import { TUnionRepo } from '@netvision/lib-api-repo'
import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'

export class AccessTokenAdapter {
  static ACCESS_TOKEN_KEY_STORAGE = 'netvision:access_token'
  static async tokenFetcher(api: TUnionRepo) {
    if (AccessTokenAdapter.getToken()) return

    try {
      const { access_token: token } = await (api as LibApiRepository).getAccessToken()
      AccessTokenAdapter.setToken(token)
    } catch (e) {
      console.error(e)
    }
  }

  static setToken(token: string) {
    sessionStorage.setItem(AccessTokenAdapter.ACCESS_TOKEN_KEY_STORAGE, token)
  }

  static getToken() {
    return sessionStorage.getItem(AccessTokenAdapter.ACCESS_TOKEN_KEY_STORAGE)
  }
}
