import './set-public-path'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
// @ts-ignore
import singleSpaReact from 'single-spa-react'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err: any, info: any, props: any) {
    // https://reactjs.org/docs/error-boundaries.html
    console.error(err, info, props)
    return null
  }
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount
export const update = lifecycles.update
