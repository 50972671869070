/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { Loader } from '../'
import { useGetPreview, usePlayerStore } from '../../hooks'

const previewWrapper = css`
  bottom: 0;
  left: 0;
  /* top: -40px; */
  position: absolute;
  border: 2px solid white;
  width: 120px;
  height: 76px;
  opacity: 0;
  pointer-events: none;
  transform: translate(0px, 0px);
  border-radius: 4px;
  background: black;

  &.is-fullscreen {
    width: 185px;
    height: 117px;
  }

  &::before {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 4px;
    content: attr(time);
    font-size: 12px;
    color: var(--text-color);
  }

  video {
    width: 100%;
    height: 100%;
  }
`

interface Props {
  snapshotUrl?: string
}

interface MovePreviewParams {
  x: number
  y: number
  label: string
}

export interface ProgressPreviewApi {
  getElement: () => HTMLDivElement
  toggleVisible: (flag: boolean) => void
  move: (config: MovePreviewParams) => void
  fetchPoster: (url: string) => () => void
}

enum PreviewStatus {
  loading,
  error,
  default,
  loaded
}

const ProgressPreview = observer<Props, ProgressPreviewApi>(
  (props, ref) => {
    const { snapshotUrl } = props
    const previewNode = useRef<HTMLVideoElement>(null!)
    const elementRef = useRef<HTMLDivElement>(null!)
    const { isFullscreen } = usePlayerStore()
    const [status, setPreviewStatus] = useState<PreviewStatus>(PreviewStatus.default)
    const { fetchPreview } = useGetPreview()

    const initializePreview = useCallback(
      async (snapshotUrl: string, signal: AbortSignal) => {
        try {
          setPreviewStatus(PreviewStatus.loading)
          const previewBlob = await fetchPreview(snapshotUrl, signal)
          if (signal.aborted) return

          if (!previewBlob) {
            setPreviewStatus(PreviewStatus.error)
            return
          }

          const url = window.URL.createObjectURL(previewBlob)
          const isVideoPoster = previewBlob.type.startsWith('video')
          previewNode.current[isVideoPoster ? 'src' : 'poster'] = url
          setPreviewStatus(PreviewStatus.loaded)
        } catch (e) {
          console.error(e)
          setPreviewStatus(PreviewStatus.error)
        }
      },
      [fetchPreview]
    )

    useEffect(() => {
      if (!snapshotUrl) return
      const abrController = new AbortController()
      const video = previewNode.current
      initializePreview(snapshotUrl, abrController.signal)

      return () => {
        window.URL.revokeObjectURL(video.poster)
        window.URL.revokeObjectURL(video.src)
        abrController.abort()
      }
    }, [initializePreview, snapshotUrl])

    const toggleVisible = useCallback((flag: boolean) => {
      elementRef.current.style.opacity = flag ? '1' : '0'
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        fetchPoster: (snapshotUrl) => {
          const abrController = new AbortController()
          initializePreview(snapshotUrl, abrController.signal)
          return () => abrController.abort()
        },
        move: (config: MovePreviewParams) => {
          const { label, x, y } = config
          elementRef.current.style.transform = `translate(${x}px, ${y}px)`
          elementRef.current.setAttribute('time', label)
        },
        getElement: () => elementRef.current,
        toggleVisible
      }),
      [toggleVisible]
    )

    const extraClassNames = isFullscreen ? 'is-fullscreen' : ''

    return (
      <div className={`preview ${extraClassNames}`} ref={elementRef} css={previewWrapper}>
        {status === PreviewStatus.loading && <Loader />}
        <video ref={previewNode} />
      </div>
    )
  },
  { forwardRef: true }
)

const ProgressPreviewWithMemo = React.memo(ProgressPreview)
export { ProgressPreviewWithMemo as ProgressPreview }
