import { css } from '@emotion/react'

export const dialogMessage = css`
  width: calc(480rem / var(--bfs));
  line-height: 1.5;
`
export const errorColor = { color: 'var(--error-color)' }

export const processStateKeys = {
  error: 'error',
  loading: 'loading'
} as const

export type TProcessKeys = keyof typeof processStateKeys
