import { css } from '@emotion/react'

export const baseButtonStyle = css`
  &.p-button.p-component.p-button-icon-only {
    padding: 0;
    width: 36px;
    height: 36px;
    border: 0;
  }

  &.p-button-secondary.p-button.p-component.p-button-icon-only {
    width: 48px;
    height: 40px;
    border-radius: 10px;
    background: var(--bg-gradient-dark);
    border: 0;
    font-size: 20px;

    span:first-child {
      color: var(--text-color-secondary);
    }

    &:hover:not(:disabled) {
      span:first-child {
        color: var(--primary-color);
      }
    }
  }

  font-size: 26px;
  padding: 0;
  background-color: transparent;

  &:hover:not(:disabled) {
    background-color: transparent;

    span:first-child {
      color: var(--primary-color);
    }
  }
`
