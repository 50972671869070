import { MutableRefObject, useEffect, useRef } from 'react'
import { reaction } from 'mobx'

import { MountHooks, OverlayParams } from '../IWidgetProps'
import { usePlayerStore } from './usePlayerStore'

export const useMountOverlay = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  overlayMountHooks?: MountHooks<OverlayParams>
) => {
  const externalOverlayRef = useRef<HTMLDivElement | null>(null)
  const store = usePlayerStore()
  useEffect(() => {
    const video = videoRef.current
    const externalOverlay = externalOverlayRef.current
    if (!store || !video || !externalOverlay || !overlayMountHooks) return

    const disposers = [
      reaction(
        () => [store.naturalVideoWidth, store.naturalVideoHeight],
        ([width, height]) => {
          if (height <= 0 || width <= 0) return

          overlayMountHooks.unmount()
          overlayMountHooks.mount({
            overlayContainer: externalOverlay,
            overlayBase: video,
            naturalWidth: width,
            naturalHeight: height
          })
        }
      ),
      () => overlayMountHooks.unmount()
    ]

    return disposers.forEach((d) => d())
  }, [videoRef, store, overlayMountHooks])
  return externalOverlayRef
}
