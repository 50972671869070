import React, { ReactNode, createContext, useMemo } from 'react'

import { IWidgetProps, IWidgetPropsProvider } from '../IWidgetProps'
import { normalizeProps } from '../utils'

export const WidgetPropsContext = createContext<IWidgetPropsProvider>(null!)

export const WidgetPropsProvider = ({
  value,
  children
}: {
  value: IWidgetProps
  children: ReactNode
}) => {
  const domElement = useMemo(() => value.domElement, [])
  const normalizedProps = useMemo(
    () => normalizeProps({ ...value, domElement }),
    [value, domElement]
  )
  return (
    <WidgetPropsContext.Provider value={normalizedProps}>{children}</WidgetPropsContext.Provider>
  )
}
