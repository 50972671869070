import { TUnionRepo } from '@netvision/lib-api-repo'

export const getAnalyticsByCameraId = async (api: TUnionRepo, cameraId: string) => {
  try {
    const { results } = await api.getEntitiesList({
      limiter: {
        type: 'Assignment'
      },
      filter: {
        count: true,
        q: `entityType=='Camera';entityId=='${cameraId}';status=='Started'`
      }
    })
    return results.length
  } catch (e) {
    console.error(e)
    throw new Error('Can not get analytics count on camera')
  }
}
