import React, { createContext, ReactNode, useMemo } from 'react'
import { repositoryGetter, TUnionRepo } from '@netvision/lib-api-repo'
import { useWidgetProps } from '../hooks'

export const LibApiRepoContext = createContext({} as TUnionRepo)

const DEFAULT_LIB_API = {
  name: 'lib-api',
  mixins: ['cubejs']
}

export const LibApiRepoProvider = ({ children }: TApiRepositoryProviderProps) => {
  const { props } = useWidgetProps()
  const { lib } = props!
  const api = useMemo(() => repositoryGetter(lib || DEFAULT_LIB_API) as TUnionRepo, [lib])
  return <LibApiRepoContext.Provider value={api}>{children}</LibApiRepoContext.Provider>
}

export type TApiRepositoryProviderProps = {
  children: ReactNode
}
