/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { ReactNode, memo, useLayoutEffect, useRef, useState } from 'react'
import { useGetPresetPreview } from '../hooks'

interface Props {
  children: ReactNode
  presetId?: string
}

const previewWrapper = css`
  position: relative;

  > video {
    pointer-events: none;
    border: calc(1rem / var(--bfs)) solid var(--player-video-bg);
    border-radius: var(--border-radius);
    background: var(--player-video-bg);
    position: absolute;
    bottom: calc(100% + 10rem / var(--bfs));
    left: calc(-54rem / var(--bfs));
    width: calc(210rem / var(--bfs));
    height: unset;
  }

  > video[data-visible] {
    transition: opacity var(--transition-duration) ease;
  }

  > video[data-visible='false'] {
    opacity: 0;
  }

  > video[data-visible='true'] {
    opacity: 1;
  }
`

export const WithPresetPreview = memo((props: Props) => {
  const { children, presetId } = props
  const { fetchPreview } = useGetPresetPreview()

  const [imagePreviewLink, setImagePreviewLink] = useState('')
  const [visible, setVisible] = useState(false)

  const nodeRef = useRef<HTMLDivElement>(null!)

  const isVisible = visible && imagePreviewLink

  const onEnter = () => setVisible(true)
  const onLeave = () => setVisible(false)

  useLayoutEffect(() => {
    if (!presetId) return
    const abortController = new AbortController()

    let objectUrl = ''
    const getPreview = async () => {
      try {
        setImagePreviewLink('')
        const blob = await fetchPreview(presetId, abortController.signal)
        if (abortController.signal.aborted || !blob) return
        objectUrl = window.URL.createObjectURL(blob)
        setImagePreviewLink(objectUrl)
      } catch (e) {
        console.error(e)
        setImagePreviewLink('')
      }
    }

    getPreview()
    return () => {
      window.URL.revokeObjectURL(objectUrl)
      abortController.abort()
    }
  }, [fetchPreview, presetId])

  if (!presetId) return <React.Fragment>{children}</React.Fragment>

  return (
    <div ref={nodeRef} onMouseLeave={onLeave} onMouseOver={onEnter} css={previewWrapper}>
      {children}
      <video src={imagePreviewLink} data-visible={isVisible} />
    </div>
  )
})
