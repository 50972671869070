import React, { MutableRefObject } from 'react'
import { Button } from 'primereact/button'

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { PlayerApi } from '../../../packages/Player'
import { TOOLTIP_OPTIONS } from './helpers'
import { useLocaleContext } from '@src/Root'
import { baseButtonStyle } from './styles'

interface Props {
  forwardAmount?: number
  disabled?: boolean
  decrease?: boolean
  playerApi: MutableRefObject<PlayerApi>
  secondary?: boolean
  // for sync archive mode
  handleExternalClick?: () => void
}

const buttonStyles = css`
  &.decrease {
    transform: scale(-1, 1);
  }
`

export const ForwardButton = (props: Props) => {
  const { disabled, playerApi, decrease, forwardAmount = 5, secondary, handleExternalClick } = props
  const { localize } = useLocaleContext()

  const handleClick = () => {
    const bridgeApi = playerApi.current.getPlayerInstance()?.bridgeApi
    if (!bridgeApi) return

    const delta = decrease ? -forwardAmount : forwardAmount
    bridgeApi.setCurrentTime(bridgeApi.currentTime! + delta)
  }

  let extraClassName = ''
  if (secondary) extraClassName += 'p-button-secondary'

  return (
    <Button
      className={`${decrease ? 'decrease' : ''} ${extraClassName}`}
      css={[baseButtonStyle, buttonStyles]}
      onClick={handleExternalClick || handleClick}
      disabled={disabled}
      tooltipOptions={TOOLTIP_OPTIONS}
      icon="mdi mdi-fast-forward"
      tooltip={localize('messages.fiveSeconds')}
    />
  )
}
