import React, { ReactNode, createContext, useMemo } from 'react'
import { LockingCamera } from '@netvision/lib-camera-locking/dist/src/modules'

interface ILockingProvider {
  getCameraLocking: InstanceType<typeof LockingCamera>['getCameraLocking']
}

export const LockingContext = createContext<ILockingProvider>(null!)

export const LockingProvider = ({ children }: { children: ReactNode }) => {
  const lockingService = window.CAMERA_LOCKING as ILockingProvider
  const value = useMemo(() => (lockingService), [])
  return <LockingContext.Provider value={value}>{children}</LockingContext.Provider>
}
