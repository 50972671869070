import React, { ReactNode, createContext, useMemo } from 'react'
import { PlayerStore } from '../store/player-store'
export const PlayerStoreContext = createContext<PlayerStore>(null!)

export const PlayerStoreProvider = ({ children }: { children: ReactNode }) => {
  const playerStore = useMemo(() => {
    return new PlayerStore()
  }, [])
  return <PlayerStoreContext.Provider value={playerStore}>{children}</PlayerStoreContext.Provider>
}
