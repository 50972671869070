import { action, observable } from 'mobx'
import { Player } from '@netvision/lib-player'

class PlayerStore {
  @observable playerId = ''
  @observable isFullscreen = false
  @observable isPlaying = false
  @observable isWaiting = false
  @observable isPlayerStalled = false
  @observable isMuted = true
  @observable parallelMode = false
  @observable playerInst: Player
  @observable videoElement: HTMLVideoElement

  // group - если нет группы убивать

  @observable volume = 0

  @observable naturalVideoHeight = 0
  @observable naturalVideoWidth = 0

  @action.bound
  setPlayerInst(playerInst: Player, videoElement: HTMLVideoElement) {
    this.playerInst = playerInst
    this.videoElement = videoElement
  }

  @action.bound
  setPlayerId(id: string) {
    this.playerId = id
  }

  @action.bound
  setVideoElement(videoElement: HTMLVideoElement) {
    this.videoElement = videoElement
  }

  @action.bound
  setIsFullscreen(flag: boolean) {
    this.isFullscreen = flag
  }

  @action.bound
  setIsPlaying(flag: boolean) {
    this.isPlaying = flag
  }

  @action.bound
  setIsWaiting(flag: boolean) {
    this.isWaiting = flag
  }

  @action.bound
  setIsPlayerStalled(flag: boolean) {
    this.isPlayerStalled = flag
  }

  @action.bound
  setIsMuted(flag: boolean) {
    this.isMuted = flag
  }

  @action.bound
  setNaturalSize(w: number, h: number) {
    this.naturalVideoHeight = h
    this.naturalVideoWidth = w
  }

  @action.bound
  setVolume(val: number) {
    this.volume = val
  }

  @action.bound
  setParallelMode(value: boolean) {
    this.parallelMode = value
  }
}

export { PlayerStore }
