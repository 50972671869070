import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'
import { useApiRepo } from '../../../hooks'
import { useCallback } from 'react'

export const useGetPresetPreview = () => {
  const api = useApiRepo() as LibApiRepository

  const fetchPreview = useCallback(
    async (presetId: string, signal: AbortSignal) => {
      const authHeaders = await api.getHeaders()
      const response = await fetch(`/gateway/cameras/v1/presets/${presetId}`, {
        headers: {
          ...authHeaders
        },
        signal
      })

      if (response.status === 200) return response.blob()
      throw Error('Preview cannot be fetched')
    },
    [api]
  )

  return {
    fetchPreview
  }
}
