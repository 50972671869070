/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useLocaleContext } from '@src/Root'
import { Fragment, useEffect, useState } from 'react'

import { Select } from '../../../components/Select/Select'

export interface VolumeControlProps {
  /** number from 0.5 to 2 */
  value?: number
  disabled?: boolean
  onSpeedChange: (value: number) => void
}

const SPEED_OPTIONS = [
  { label: '0.25', value: 0.25 },
  { label: '0.5', value: 0.5 },
  { label: '0.75', value: 0.75 },
  { label: '1', value: 1 },
  { label: '1.25', value: 1.25 },
  { label: '1.5', value: 1.5 },
  { label: '2', value: 2 },
  { label: '4', value: 4 }
]

const DEFAULT_SPEED_INDEX = 3
export const SpeedControl = ({ value, disabled, onSpeedChange }: VolumeControlProps) => {
  const [selectedSpeed, setSelectedSpeed] = useState(SPEED_OPTIONS[DEFAULT_SPEED_INDEX])
  const { localize } = useLocaleContext()

  useEffect(() => {
    if (!value) return
    const option = SPEED_OPTIONS.find(({ value: optionValue }) => value === optionValue)
    if (!option) return
    setSelectedSpeed(option)
  }, [value])

  const onSelect = (option: { id: string | number; title: string }) => {
    setSelectedSpeed({
      value: option.id as number,
      label: option.title
    })
    onSpeedChange(option.id as number)
  }

  return (
    <Fragment>
      <Select
        icon={'mdi mdi-timer'}
        disabled={disabled}
        activeId={selectedSpeed.value}
        options={SPEED_OPTIONS.map((item) => ({
          id: item.value,
          title: item.label
        }))}
        secondary
        onSelect={onSelect}
        tooltip={localize('player.speed')}
      />
    </Fragment>
  )
}
