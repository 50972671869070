import React, { useCallback, useState } from 'react'
import { Button } from 'primereact/button'
import { useCameraTimeOffset, usePlayerStore } from '@src/hooks'
import { PlayerApi } from '@src/packages/Player'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from '@src/utils/get-snapshot'
import { useLocaleContext } from '@src/Root'

interface Props {
  disabled?: boolean
  playerApi: React.MutableRefObject<PlayerApi>
  currentTime?: number
}

enum ScreenshotStatuses {
  Init = 'init',
  Loading = 'loading',
  Error = 'error',
  Complete = 'complete'
}

export const Screenshot = observer(({ playerApi, disabled, currentTime }: Props) => {
  const { localize } = useLocaleContext()
  const { naturalVideoWidth, naturalVideoHeight } = usePlayerStore()
  const [loadingStatus, setLoadingStatus] = useState<ScreenshotStatuses>(ScreenshotStatuses.Init)

  const { cameraTimeOffset } = useCameraTimeOffset()

  const handleCapture = useCallback(async () => {
    const videoNode = playerApi?.current.videoElement

    if (!videoNode) return
    try {
      setLoadingStatus(ScreenshotStatuses.Loading)
      getSnapshot(videoNode, naturalVideoWidth, naturalVideoHeight, {
        time: currentTime || Date.now(),
        offset: cameraTimeOffset || 0
      })
      setLoadingStatus(ScreenshotStatuses.Complete)
    } catch (error) {
      setLoadingStatus(ScreenshotStatuses.Error)
    }
  }, [playerApi, naturalVideoWidth, naturalVideoHeight, currentTime, cameraTimeOffset])
  return (
    <Button
      disabled={disabled}
      icon={loadingStatus === ScreenshotStatuses.Loading ? 'mdi mdi-loading mdi-spin' : ''}
      label={localize('controls.saveScreenshot')}
      className="p-button-outlined"
      onClick={handleCapture}
    />
  )
})
