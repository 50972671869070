/** @jsx jsx */
import { jsx } from '@emotion/react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import { useLocaleContext } from '@src/Root'

import { dialogMessage } from './common'
import { ICameraPreset } from '../models'

interface Props {
  preset: ICameraPreset
  onHide: () => void
}

export const UpdatePresetImageModal = (props: Props) => {
  const { onHide, preset } = props
  const { localize } = useLocaleContext()
  return (
    <Dialog
      visible
      onHide={onHide}
      closable={false}
      header={preset ? localize('ptzControls.presets.refresh') : localize('ptzControls.presets.create')}
      footer={<Button label={localize('ptzControls.presets.ok')} onClick={onHide} />}>
      <div className="p-px-3 p-input-filled" css={dialogMessage}>
        <div className="p-mb-2">{localize('ptzControls.presets.cantRefreshPresetImage')}</div>
      </div>
    </Dialog>
  )
}
