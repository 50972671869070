import { useEffect, useState } from 'react'

export const KEY_CODES = {
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  ArrowUp: 'ArrowUp',
  NumpadAdd: 'NumpadAdd',
  NumpadSubtract: 'NumpadSubtract',
  Space: 'Space',
  KeyP: 'KeyP',
  KeyT: 'KeyT',
  KeyZ: 'KeyZ',
  ShiftLeft: 'ShiftLeft'
} as const

const isValidKeyCodes = (code: string): code is keyof typeof KEY_CODES => code in KEY_CODES

export const useKeyDown = () => {
  const [keyPressed, setKeyPressed] = useState<Set<keyof typeof KEY_CODES>>(new Set())

  useEffect(() => {
    const keyPressedNow = new Set()

    const handleKeyDown = (e: KeyboardEvent) => {
      if (document.activeElement === document.body) e.preventDefault()
      const pressedKey = e.code
      if (!isValidKeyCodes(pressedKey)) return
      if (keyPressedNow.has(pressedKey)) return
      keyPressedNow.add(pressedKey)
      setKeyPressed((prev) => new Set([...prev, pressedKey]))
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      if (document.activeElement === document.body) e.preventDefault()
      const pressedKey = e.code
      if (!isValidKeyCodes(pressedKey)) return
      if (keyPressedNow.has(pressedKey)) keyPressedNow.delete(pressedKey)
      setKeyPressed((prev) => {
        prev.delete(pressedKey)
        return new Set([...prev])
      })
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return {
    KEY_CODES,
    keyPressed
  }
}
