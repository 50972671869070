/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Button } from 'primereact/button'

import { useApiRepo, useToast, useWidgetProps } from '../../../../hooks'
import { PresetFeature } from '../../..'
import { usePTZStore } from '../../hooks'
import { activateButtonStyle } from './style'
import { observer } from 'mobx-react-lite'
import { getAnalyticsByCameraId } from '../../utils'
import { PlayerModes } from '../../../../IWidgetProps'
import { useLocaleContext } from '@src/Root'

export const ActivatePTZButton = observer(({ disabled }: { disabled?: boolean }) => {
  const [loading, setLoading] = useState(false)
  const { props } = useWidgetProps<PlayerModes.Live>()
  const { refetch: fetchPresets } = PresetFeature.PresetHooks.usePresetData()
  const { id: cameraId } = props!

  const {
    isPTZConnectionError,
    isPTZPanelOpen,
    isPTZBlock,
    setActiveAnalyticsCount,
    togglePTZPanel,
    toggleConnectionModal,
    toggleBlockedModal
  } = usePTZStore()
  const api = useApiRepo()
  const toast = useToast()

  const { localize } = useLocaleContext()

  const handleActivePTZ = () => {
    if (isPTZBlock) {
      toggleBlockedModal(true)
      return
    }

    if (isPTZPanelOpen) {
      togglePTZPanel(false)
      return
    }

    if (isPTZConnectionError) {
      toggleConnectionModal(true)
      return
    }

    const checkIsAvailablePTZ = async () => {
      try {
        setLoading(true)
        const analyticsCount = await getAnalyticsByCameraId(api, cameraId)
        if (analyticsCount > 0) {
          setActiveAnalyticsCount(analyticsCount)
          return
        }

        await fetchPresets()
        togglePTZPanel(true)
      } catch (e) {
        toast.current.show({
          severity: 'error',
          summary: '',
          detail: localize('ptzControls.presets.errorData')
        })
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    checkIsAvailablePTZ()
  }

  useEffect(() => {
    if (disabled) {
      togglePTZPanel(false)
    }
  }, [disabled, togglePTZPanel])

  return (
    <div className='ptz-active-button' css={activateButtonStyle}>
      <Button
        disabled={disabled || loading}
        onClick={handleActivePTZ}
        className={!isPTZPanelOpen ? 'p-button-outlined' : 'p-button-secondary'}
        label={localize('controls.ptz')}
        icon={loading ? 'mdi mdi-18px mdi-loading mdi-spin' : 'mdi mdi-18px mdi-gamepad-up'}
      />
    </div>
  )
})
