/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { FC } from 'react'

// language=SCSS
const blurCss = css`
  & {
    --__color: var(--primary-color);
    --__box-shadow: 0 0 calc(40rem / var(--bfs)) calc(10rem / var(--bfs)) var(--__color),
      0 0 calc(30rem / var(--bfs)) calc(5rem / var(--bfs)) var(--__color);
    pointer-events: none;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: calc(1rem / var(--bfs));
    left: 0;

    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: transparent;
      box-shadow: var(--__box-shadow);
    }
  }
`

export const Blur: FC<{ color?: string; boxShadow?: string }> = ({ color, boxShadow }) => {
  return (
    <div
      css={blurCss}
      // @ts-ignore
      style={{ '--__color': color, '--__box-shadow': boxShadow }}
    />
  )
}
