import React, { useEffect } from 'react'
import { ActivatePTZButton } from '..'
import { PresetViews } from '../../../Presets'
import { PTZHooks } from '../..'
import { usePlayerStore, useWidgetProps } from '../../../../hooks'
import { PlayerModes } from '../../../../IWidgetProps'
import { isBlockedPTZControlsNow } from '../../../../modules/Live/helpers'
import { LiveModuleStore } from '../../../../modules/Live/store/live-module-store'
import { observer } from 'mobx-react-lite'

export const PTZButtons = observer(
  ({
    loading,
    disabled,
    liveModuleStore
  }: {
    loading: boolean
    disabled?: boolean
    liveModuleStore: LiveModuleStore
  }) => {
    const { props: widgetProps } = useWidgetProps<PlayerModes.Live>()
    const { features } = widgetProps!
    const { isFullscreen, naturalVideoHeight, naturalVideoWidth } = usePlayerStore()
    const { isPTZSupport, setIsPTZBlock } = PTZHooks.usePTZStore()

    const hasNaturalMediaResourceSizes = Boolean(naturalVideoHeight) && Boolean(naturalVideoWidth)
    const isPtzButtonOnlyFullscreen = features?.ptz?.mode === 'default' ? true : isFullscreen
    const canIShowActivatePTZButton =
      isPtzButtonOnlyFullscreen && isPTZSupport && hasNaturalMediaResourceSizes
    const canCreateOrUpdatePtzPreview =
      features?.updatePresetImageOn && !isPTZSupport && typeof isPTZSupport !== 'undefined'

    useEffect(() => {
      setIsPTZBlock(
        !liveModuleStore.lockingCameraConfig.canI?.changeLockedControls &&
          isBlockedPTZControlsNow(liveModuleStore.lockingCameraConfig)
      )
    }, [liveModuleStore.lockingCameraConfig, setIsPTZBlock])
    return (
      <>
        {canIShowActivatePTZButton && <ActivatePTZButton disabled={disabled} />}
        {canCreateOrUpdatePtzPreview && (
          <PresetViews.CreateOrUpdatePresetPreviewButton
            loading={loading}
            mode={PlayerModes.Live}
          />
        )}
      </>
    )
  }
)
