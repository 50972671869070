import { useMemo } from 'react'
import { PlayerModes } from '../../../IWidgetProps'

export const useStreamsValidation = () => {
  const isStreamValid = <T extends PlayerModes>(
    moduleType: T,
    stream: Record<string, any> | null
  ) => {
    if (!stream) return false
    switch (moduleType) {
      case PlayerModes.Live:
        if (!('wsStreamUrl' in stream) && !('snapshotStreamUrl' in stream)) {
          console.error(
            'Stream of type "Live" must have wsStreamUrl or snapshotStreamUrl properties'
          )
          return false
        }
        return true
      case PlayerModes.Archive:
        if (!('dvrTimelines' in stream) || !stream.dvrTimelines.length) {
          console.error(
            'Stream of type "Archive" must have dvrTimelines property, and this property must contain at least one item'
          )
          return false
        }
        return true
      case PlayerModes.Saved:
        if (!('streamUrl' in stream) || !stream.streamUrl) {
          console.error(
            'Stream of type "RecordOrFile" must have streamUrl property, and this property must contain at least one item'
          )
          return false
        }
        return true
      default:
        console.error('Unknown module type')
        return false
    }
  }

  return useMemo(() => ({ isStreamValid }), [])
}
