/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import React, { Fragment } from 'react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import { useLocaleContext } from '@src/Root'
import { usePTZStore } from '../hooks'

const unlockMessage = css`
  width: calc(480rem / var(--bfs));
  line-height: 1.5;
`

interface Props {
  count: number
}

export const PTZUnLockModal = (props: Props) => {
  const { count } = props
  const { togglePTZPanel, setActiveAnalyticsCount } = usePTZStore()
  const { localize } = useLocaleContext()

  const handleAnalyticsCountConfirm = (isConfirm: boolean) => {
    setActiveAnalyticsCount(0)
    togglePTZPanel(isConfirm)
  }

  const onCancel = () => handleAnalyticsCountConfirm(false)
  const onProceed = () => handleAnalyticsCountConfirm(true)

  return (
    <Dialog
      visible
      closable={false}
      onHide={onCancel}
      header={localize('ptzControls.unlock.header')}
      footer={
        <Fragment>
          <Button
            icon="mdi mdi-18px mdi-lock-open-variant-outline"
            className="p-button-danger"
            label={localize('ptzControls.unlock.yes')}
            onClick={onProceed}
          />
          <Button
            onClick={onCancel}
            className="p-button-secondary p-button-outlined"
            label={localize('ptzControls.unlock.no')}
          />
        </Fragment>
      }>
      <div className="p-px-3" css={unlockMessage}>
        {localize('ptzControls.unlock.message', count.toString())}
      </div>
    </Dialog>
  )
}
