import React from 'react'

import { PTZBlockedModal, PTZConnectModal, PTZUnLockModal } from '../../../modals'
import { usePTZStore } from '../../../hooks'
import { observer } from 'mobx-react-lite'

export const PTZModals = observer(() => {
  const {
    activeAnalyticsCount,
    isOpenConnectionModal,
    isOpenBTZBlockModal,
    toggleBlockedModal,
    toggleConnectionModal
  } = usePTZStore()

  const needShowWarningModalByStartedAnalytics = activeAnalyticsCount > 0
  return (
    <>
      {needShowWarningModalByStartedAnalytics && <PTZUnLockModal count={activeAnalyticsCount} />}
      {isOpenConnectionModal && <PTZConnectModal onHide={() => toggleConnectionModal(false)} />}
      {isOpenBTZBlockModal && <PTZBlockedModal onHide={() => toggleBlockedModal(false)} />}
    </>
  )
})
