import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'

import { useToast, useWidgetProps } from '../../../hooks'
import { PTZHooks } from '../../PTZ'
import { WithPresetPreview } from './WithPresetPreview'
import { useStreamSwitcher } from '../../StreamSwitcher/hooks'
import { usePresetCommand, usePresetData } from '../hooks'
import { PlayerModes } from '../../../IWidgetProps'
import { useLocaleContext } from '@src/Root'

const REQUEST_SETTINGS = { enable: false }

export const CreateOrUpdatePresetPreviewButton = ({ mode, loading: moduleLoading }: { mode: PlayerModes, loading: boolean }) => {
  const { props: widgetProps } = useWidgetProps<PlayerModes.Live>()
  const { id } = widgetProps!

  const [loading, setLoading] = useState(false)
  const isFirstLoad = useRef(false)

  const { localize } = useLocaleContext()
  const toast = useToast()

  const { presets, refetch: fetchPresets, isLoading: presetLoading } = usePresetData()
  const { request: fetchPTZData } = PTZHooks.useGetPTZData(id, REQUEST_SETTINGS)
  const { getActiveStream } = useStreamSwitcher()
  const { createCameraPreset, updatePresetPreview } = usePresetCommand()

  useEffect(() => {
    if (!presets.length || isFirstLoad.current) return
    isFirstLoad.current = true
    fetchPresets()
  }, [presets])

  const [activePreset] = presets
  const isUpdatePreview = activePreset?.id
  const handlePresetAction = async () => {
    try {
      setLoading(true)
      const ptzData = await fetchPTZData()
      if (!ptzData) throw new Error('Can not get ptz data in CreateOrUpdatePresetPreviewButton')

      if (isUpdatePreview) {
        await updatePresetPreview({
          id: activePreset.id,
          title: activePreset.title,
          positions: ptzData.status.position,
          streamId: getActiveStream(mode)?.id
        })
      }
      await createCameraPreset(localize('ptzControls.defaultPresetTitle'), ptzData.status)
    } catch (e) {
      console.error(e)
      toast.current.show({
        severity: 'error',
        summary: '',
        detail: isUpdatePreview ? localize('ptzControls.presets.presetUpdateError') : localize('ptzControls.presets.presetCreateError'),
      })
    } finally {
      setLoading(false)
    }
  }

  const componentLoading = presetLoading || loading || moduleLoading

  return isUpdatePreview
    ? (
    <WithPresetPreview presetId={activePreset?.id}>
      <Button
        disabled={componentLoading}
        onClick={handlePresetAction}
        className={'p-button-text'}
        label={localize('ptzControls.presets.refreshPresetImage')}
        icon={componentLoading ? 'mdi mdi-18px mdi-loading mdi-spin' : 'mdi mdi-20px mdi-camera'}
      />
    </WithPresetPreview>
  )
  : (
    <Button
      disabled={componentLoading}
      onClick={handlePresetAction}
      className="p-button-text"
      label={
        !activePreset?.id
          ? localize('controls.createPresetImage')
          : localize('ptzControls.presets.refreshPresetImage')
      }
      icon={componentLoading ? 'mdi mdi-18px mdi-loading mdi-spin' : 'mdi mdi-20px camera'}
    />
  )
}
