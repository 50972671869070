import React, { Fragment, ReactNode } from 'react'
import { PresetDataProvider } from './Presets'
import { StreamSwitcherProvider } from './StreamSwitcher'
import { LockingProvider } from './Locking'
import { useWidgetProps } from '../hooks'
import { isNewArchiveProps, isNewLiveWidgetProps, isNewSavedProps } from '../utils/props-normalizer'
import { PTZStoreProvider } from './PTZ/providers'
export * as PTZFeature from './PTZ'
export * as PresetFeature from './Presets'
export * as StreamSwitcherFeature from './StreamSwitcher'
export * as LockingFeature from './Locking'

export const FeaturesProvider = ({ children }: { children: ReactNode }) => {
  const { props } = useWidgetProps()

  // TODO react lazy
  if (isNewLiveWidgetProps(props)) {
    const { id, defaultStreamType, defaultStreamId, batchAttributes, refetch } = props!
    return (
      <PresetDataProvider cameraId={id}>
        <PTZStoreProvider>
          <StreamSwitcherProvider
            value={{
              id,
              defaultStreamType,
              defaultStreamId,
              batchAttributes,
              refetch
            }}>
            <LockingProvider>{children}</LockingProvider>
          </StreamSwitcherProvider>
        </PTZStoreProvider>
      </PresetDataProvider>
    )
  }

  if (isNewArchiveProps(props)) {
    const { entity } = props
    if (!entity?.id) {
      console.error('can not find entity id', props)
      return null
    }
    return <Fragment>{children}</Fragment>
  }

  if (isNewSavedProps(props) && props?.record) {
    return (
      <StreamSwitcherProvider
        value={{
          id: props.record.id,
          type: props.record.type
        }}>
        {children}
      </StreamSwitcherProvider>
    )
  }

  return <>{children}</>
}
