export const localesRU = {
  archive: {
    blockedChunk: 'Участок Архива Заблокирован',
    archiveIsUnavailable: 'Не удалось загрузить архив'
  },
  messages: {
    onStalled: 'Из-за низкой скорости соединения плеер перешел в режим показа превью',
    restart: 'Возобновить',
    stop: 'Остановить',
    error: 'Ошибка',
    success: 'Успех',
    presetUpdated: 'Скриншот обновлен',
    presetError: 'Не удалось обновить скриншот',
    moveToDefaultPreset: 'Камера возвращена в позицию по умолчанию!',
    recordStatusIsUnsupported: 'Ошибка при сохранении записи',
    recordIsNotLoaded: 'Запись в процессе сохранения',
    fiveSeconds: '5 секунд',
    errorLoading: 'Ошибка загрузки архива на потоке! Попробуйте другой поток или обратитесь в тех поддержку!',
    archiveBlocked: 'Архив заблокирован',
    threadBlocked: 'Поток заблокирован',
    streamUnavailable: 'Поток недоступен',
    playerConfigError: 'Ошибка конфигурации плеера'
  },
  controls: {
    ptz: 'Управление',
    refresh: 'Обновить',
    play: 'Старт',
    pause: 'Пауза',
    volume: 'Громкость',
    enterFullscreen: 'Полноэкранный режим',
    exitFullscreen: 'Покинуть полноэкранный режим',
    updatePresetImage: 'Обновить позицию камеры',
    createPresetImage: 'Создать позицию камеры',
    saveScreenshot: 'Сохранить кадр',
    scale: {
      zoomIn: 'Увеличить масштаб',
      zoomOut: 'Уменьшить масштаб',
    }
  },
  ptzControls: {
    zoom: 'Масштаб',
    defaultPresetTitle: 'По умолчанию',
    ptzControl: 'Управление',
    unlock: {
      header: 'Разблокировать?',
      message: 'Управление камерой заблокировано, так как перемещение камеры может прервать запущенные в данный момент аналитики ({param}). Все равно продолжить? ',
      // message: 'Управление камерой заблокировано, так как перемещение камеры может прервать запущенные в данный момент аналитики ({{analyticNumber}}). Все равно продолжить? ',
      yes: 'Да',
      no: 'Нет'
    },
    blocked: {
      header: 'Камера заблокирована',
      message: 'Доступ к управлению камерой заблокирован.'
    },
    connectionError: {
      header: 'Ошибка соединения!',
      message: 'Ошибка получения PTZ статуса камеры.',
      close: 'Закрыть'
    },
    stream: {
      streamLoading: 'Загрузка',
      streamNoPreview: 'Нет превью',
      streamError: 'Нет потока',
      streamNotFound: 'Не удалось загрузить запись',
      streamNotAvailable: 'Поток недоступен',
      archiveAreaNotAvailable: 'Недоступный участок архива',
    },
    presets: {
      header: 'Позиции камеры',
      add: 'Добавить',
      create: 'Сохранить позицию',
      rename: 'Переименовать',
      goToPreset: 'Перевести камеру в позицию',
      refreshPresetImage: 'Обновить скриншот позиции',
      cantRefreshPresetImage: 'Для обновления скриншота переведите камеру в выбранную позицию',
      putPositionToActual: 'Необходимо установить камеру в позицию',
      presetPlaceholder: 'Не выбрано',
      createDetail: 'Текущая позиция камеры будет сохранена',
      createAction: 'Запомнить',
      refresh: 'Обновить позицию',
      refreshDetail: 'Предыдущая позиция будет перезаписана',
      refreshAction: 'Обновить',
      title: 'Название',
      yes: 'Да',
      no: 'Нет',
      ok: 'Ок',
      cancel: 'Отмена',
      delete: 'Удалить',
      deleteDetail: 'Удалить предустановку?',
      loadingDeleteDetail: 'Проверка аналитик...',
      // cannotDeleteDetail: 'Данная предустановка не может быть удалена, так как она связана с некоторыми аналитиками ({{analyticsCount}}).',
      cannotDeleteDetail: 'Данная предустановка не может быть удалена, так как она связана с некоторыми аналитиками ({param}).',
      unexpectedError: 'Ошибка при выполнении запроса',
      accessDenied: 'Отказано',
      accessDeniedDetail: 'Требуемые права отсутствуют.',
      needChoosePreset: 'Необходимо выбрать пресет',
      errorData: 'Ошибка получения данных',
      somethingWrong: 'Что-то пошло не так!',
      presetUpdateError: 'Ошибка обновления пресета!',
      presetCreateError: 'Ошибка создания пресета!'
    },
    close: 'закрыть',
    scale: 'Масштаб',
    sensitivity: {
      label: 'Чувствительность'
    }
  },
  player: {
    restart: 'Сначала',
    rewind: 'Назад на {param} секунд',
    // rewind: 'Назад на {seektime} секунд',
    play: 'Начать проигрывание',
    pause: 'Пауза',
    // fastForward: 'Вперед на {seektime} секунд',
    fastForward: 'Вперед на {param} секунд',
    seek: 'Поиск',
    // seekLabel: '{currentTime} от {duration}',
    seekLabel: '{param} от {param}',
    played: 'Проиграно',
    buffered: 'Буффер',
    currentTime: 'Текущее время',
    duration: 'Длительность',
    volume: 'Громкость',
    mute: 'Отключить звук',
    unmute: 'Включить звук',
    enableCaptions: 'Включить субтитры',
    disableCaptions: 'Выключить субтитры',
    download: 'Загрузить',
    enterFullscreen: 'Полноэкранный режим',
    exitFullscreen: 'Покинуть полноэкранный режим',
    frameTitle: 'Проигрыватель для {param}',
    // frameTitle: 'Проигрыватель для {title}',
    captions: 'Субтитры',
    settings: 'Настройки',
    pip: 'PIP',
    menuBack: 'Назад',
    speed: 'Скорость',
    normal: 'Нормальное',
    quality: 'Качество',
    loop: 'Зациклить',
    start: 'Начало',
    end: 'Конец',
    all: 'Все',
    reset: 'Сбросить',
    disabled: 'Отключено',
    enabled: 'Включено',
    advertisement: 'Реклама',
    selectFragment: 'Выбрать фрагмент',
    removeSelection: 'Убрать выделение',
    loadingFragment: 'Загрузка фрагмента начата!',
    errorLoadingArchive: 'Ошибка загрузки архива!',
    tryLater: 'Пожалуйста повторите позже или обратитесь в службу поддержки',
    remove: 'Убрать',
    upload: 'Скачать',
    save: 'Сохранить',
    cancel: 'Отмена',
    description: 'Описание',
    attention: 'Внимание',
    savingFragment: 'Сохранение фрагмента',
    savingFragmentInfo: 'Фрагмент сохраняется и будет добавлен в сохраненные записи в ближайшее время',
    failedSavedFragment: 'Не удалось произвести сохранение по неизвестной причине',
    blockedSection: 'Невозможно экспортировать фрагмент с заблокированным участком!',
    toPlay: 'Воспроизвести',
    errorSetting: 'Ошибка настройки плеера',
    loadArchive: 'Загрузка архива...',
    loading: 'Загрузка',
    qualityBadge: {
      2160: '4K',
      1440: 'HD',
      1080: 'HD',
      720: 'HD',
      576: 'SD',
      480: 'SD'
    }
  }
}
