/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useState } from 'react'
import { Slider } from 'primereact/slider'
import { Button } from 'primereact/button'
import { usePlayerStore, useTooltip } from '../../../hooks'
import { useLocaleContext } from '@src/Root'
import { observer } from 'mobx-react-lite'
import { TOOLTIP_OPTIONS } from './helpers'
import { baseButtonStyle } from './styles'

interface Props {
  id: string
  disabled?: boolean
  secondary?: boolean
}

const controlWrapper = css`
  position: relative;

  &.is-horizontal {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    .slider-wrapper {
      display: flex;
      align-items: center;
      position: static;
      margin-right: 10px;
      transform: unset;
      width: 200px;
      height: 40px;

      .p-slider.p-component {
        width: 100%;
      }
    }
  }
`

const sliderWrapper = css`
  position: absolute;
  padding: calc(12rem / var(--bfs)) calc(8rem / var(--bfs));
  top: 0;
  left: 50%;
  border-radius: calc(4rem / var(--bfs));
  background: var(--surface-b);
  transform: translateY(-100%) translateX(-50%);
  box-shadow: var(--shadow-raised);

  &.secondary {
    top: calc(-5rem / var(--bfs));
  }
`

export const VolumeControl = observer((props: Props) => {
  const { localize } = useLocaleContext()
  const { showTooltip } = useTooltip()
  const { disabled } = props
  const [isOpen, setIsOpen] = useState(false)
  const { isMuted, volume, setIsMuted, setVolume } = usePlayerStore()

  const buttonIconName = volume === 0 || isMuted ? 'mdi-volume-off' : 'mdi-volume-high'

  const toggleSlider = () => setIsOpen((prev) => !prev)
  const toggleMuted = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsMuted(!isMuted)
  }

  let extraClassName = ''
  if (props.secondary) extraClassName += 'p-button-secondary'

  return (
    <div css={controlWrapper}>
      <Button
        id={`volume-button-${props.id}`}
        css={baseButtonStyle}
        onDoubleClick={toggleMuted}
        onClick={toggleSlider}
        disabled={disabled}
        className={extraClassName}
        icon={`mdi mdi-32px ${buttonIconName}`}
        // Do not use tooltip property because of TooltipProvider fullscreen-related functionality
        // Use onMouseEnter event handler instead
        onMouseEnter={() =>
          showTooltip({
            elementId: `volume-button-${props.id}`,
            message: localize('controls.volume'),
            config: TOOLTIP_OPTIONS
          })
        }
      />
      {isOpen && (
        <div className={`slider-wrapper ${props.secondary ? 'secondary' : ''}`} css={sliderWrapper}>
          <Slider
            value={isMuted ? 0 : volume * 100}
            min={0}
            max={100}
            orientation="vertical"
            onChange={(e) => {
              if (typeof e.value !== 'number') return
              setVolume(e.value / 100)
            }}
          />
        </div>
      )}
    </div>
  )
})
