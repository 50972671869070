import { useWidgetProps } from '../../../hooks'
import { PlayerModes } from '../../../IWidgetProps'
import { usePTZCommands } from './usePTZCommands'
import { usePTZStore } from './usePTZStore'

export const usePTZActions = () => {
    const { props: widgetProps } = useWidgetProps<PlayerModes.Live>()
    const { id } = widgetProps!
    const { ptzSettings } = usePTZStore()
    const commands = usePTZCommands(id, ptzSettings)
    return commands
}
