import { IPtzData } from '../models'
import { useApiRepo } from '../../../hooks'
import { useCallback, useEffect, useState } from 'react'
import { TUnionRepo } from '@netvision/lib-api-repo'

const getPTZData = async (id: string, api: TUnionRepo) => {
  try {
    const cameraListData = await api.getEntitiesList<IPtzData>({
      limiter: {
        id,
        type: 'Camera'
      },
      filter: {
        attrs: 'status,ptzSettings'
      }
    })

    const { results } = cameraListData

    if (results.length !== 1) {
      console.error('Unexpected result, expect exactly one entity', cameraListData)
      return null
    }

    const [cameraData] = results
    if (!cameraData.status) {
      console.error('Camera does not have required ptz fields: "status"', cameraListData)
      return null
    }

    if (!cameraData.ptzSettings) {
      console.error('Camera does not have required ptz fields: "ptzSettings"', cameraListData)
      return null
    }

    return cameraData
  } catch (e) {
    console.error(e)
    return null
  }
}

export const useGetPTZData = (id: string, { enable }: {enable: boolean}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [ptzData, setPTZData] = useState<Awaited<ReturnType<typeof fetchPTZData>>>()
  const api = useApiRepo()

  const fetchPTZData = useCallback(() => getPTZData(id, api), [api, id])

  const updatePtzData = useCallback(
    async () => {
      try {
        setIsLoading(true)
        const ptzData = await fetchPTZData()
        setPTZData(ptzData)
        return ptzData
      } catch (e: unknown) {
        console.error(e)
        return undefined
      } finally {
        setIsLoading(false)
      }
    },
    [fetchPTZData]
  )

  useEffect(() => {
    if (!id || !enable) return
    updatePtzData()
  }, [enable, id, updatePtzData])

  return {
    refetch: updatePtzData,
    request: fetchPTZData,
    data: ptzData,
    loading: isLoading
  }
}
