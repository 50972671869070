import { useApiRepo } from '../../../hooks'
import { IPtzSettings, IPtzValues } from '../models'

const saveCameraTimeoutMoveValue = (timeout?: number) => {
  const DEFAULT_CAMERA_TIMEOUT_MOVE = 30 * 1000 // 30sec
  const MIN_CAMERA_TIMEOUT_MOVE = 1 * 1000 // 1 sec
  const MAX_CAMERA_TIMEOUT_MOVE = 60 * 1000 // 1 min

  const onlyPositive = Math.abs(timeout || DEFAULT_CAMERA_TIMEOUT_MOVE)
  const betweenSecAndMin =
    onlyPositive >= MIN_CAMERA_TIMEOUT_MOVE && onlyPositive <= MAX_CAMERA_TIMEOUT_MOVE
      ? onlyPositive
      : DEFAULT_CAMERA_TIMEOUT_MOVE

  return betweenSecAndMin
}

export const usePTZApi = (id: string) => {
  const api = useApiRepo()

  const stop = async () => {
    await api.updateEntity({
      id,
      type: 'Camera',
      stop: {}
    })
  }

  const relativeMove = async (offset: Partial<IPtzValues>) =>
    api.updateEntity({
      id,
      type: 'Camera',
      relativeMove: {
        x: 0,
        y: 0,
        zoom: 0,
        speed: 1,
        ...offset
      }
    })

  const continuousMove = async (speed: Partial<IPtzValues>, ptzSettings?: IPtzSettings) =>
    await api.updateEntity({
      id,
      type: 'Camera',
      continuousMove: {
        timeout: saveCameraTimeoutMoveValue(ptzSettings?.timeout),
        x: 0,
        y: 0,
        zoom: 0,
        ...speed
      }
    })

  const absoluteMove = (position: Partial<IPtzValues>) =>
    api.updateEntity({
      id,
      type: 'Camera',
      absoluteMove: {
        ...position
      }
    })

  const updateSettings = async (ptzSettings: IPtzSettings) =>
    await api.updateEntity({
      id,
      type: 'Camera',
      ptzSettings
    })

  return {
    stop,
    relativeMove,
    continuousMove,
    absoluteMove,
    updateSettings
  }
}
