export const localesEN = {
  archive: {
    blockedChunk: 'Archive Area Blocked',
    archiveIsUnavailable: 'Failed to load archive'
  },
  messages: {
    onStalled: 'Due to low connection speed, the player switched to preview mode',
    restart: 'Resume',
    stop: 'Stop',
    error: 'Error',
    success: 'Success',
    presetUpdated: 'Screenshot updated',
    presetError: 'Failed to update screenshot',
    moveToDefaultPreset: 'The camera has been returned to its default position!',
    recordStatusIsUnsupported: 'Error while saving entry',
    recordIsNotLoaded: 'Recording in progress',
    fiveSeconds: '5 seconds',
    errorLoading: 'Error loading archive on stream! Try another stream or contact technical support!',
    archiveBlocked: 'Archive blocked',
    threadBlocked: 'Thread blocked',
    streamUnavailable: 'Stream unavailable',
    playerConfigError: 'Player configuration error'
  },
  controls: {
    ptz: 'Control',
    refresh: 'Update',
    play: 'Start',
    pause: 'Pause',
    volume: 'Volume',
    enterFullscreen: 'Full screen mode',
    exitFullscreen: 'Leave full screen mode',
    updatePresetImage: 'Update camera position',
    createPresetImage: 'Create camera position',
    saveScreenshot: 'Save frame',
    scale: {
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
    }
  },
  ptzControls: {
    zoom: 'Scale',
    defaultPresetTitle: 'Default',
    ptzControl: 'Control',
    unlock: {
      header: 'Unblock?',
      message:
        'Camera control is blocked because moving the camera may interrupt currently running analytics ({{analyticNumber}}). Should it continue anyway? ',
      yes: 'Yes',
      no: 'No'
    },
    blocked: {
      header: 'Camera is locked',
      message: 'Access to camera control is blocked.'
    },
    connectionError: {
      header: 'Connection error!',
      message: 'Error getting PTZ camera status.',
      close: 'Close'
    },
    stream: {
      streamLoading: 'Loading',
      streamNoPreview: 'No preview',
      streamError: 'No flow',
      streamNotFound: 'Failed to load entry',
      streamNotAvailable: 'Stream unavailable',
      archiveAreaNotAvailable: 'Inaccessible archive section'
    },
    presets: {
      header: 'Camera positions',
      add: 'Add',
      create: 'Save position',
      rename: 'Rename',
      goToPreset: 'Move the camera to position',
      refreshPresetImage: 'Update position screenshot',
      cantRefreshPresetImage: 'To update the screenshot, move the camera to the selected position',
      putPositionToActual: 'The camera needs to be positioned',
      presetPlaceholder: 'Not chosen',
      createDetail: 'The current camera position will be saved',
      createAction: 'Remember',
      refresh: 'Update position',
      refreshDetail: 'The previous position will be overwritten',
      refreshAction: 'Update',
      title: 'Name',
      yes: 'Yes',
      no: 'No',
      ok: 'Ok',
      cancel: 'Cancel',
      delete: 'Delete',
      deleteDetail: 'Delete preset?',
      loadingDeleteDetail: 'Analytics check...',
      cannotDeleteDetail:
        'This preset cannot be deleted as it is associated with some analytics ({{analyticsCount}}).',
      unexpectedError: 'Error executing request',
      accessDenied: 'Denied',
      accessDeniedDetail: 'The required rights are missing.',
      needChoosePreset: 'You need to select a preset',
      errorData: 'Error receiving data',
      somethingWrong: 'Something went wrong!',
      presetUpdateError: 'Preset update error!',
      presetCreateError: 'Error creating preset!'
    },
    close: 'Close',
    scale: 'Scale',
    sensitivity: {
      label: 'Sensitivity'
    }
  },
  player: {
    restart: 'At first',
    rewind: 'Backward {seektime} seconds',
    play: 'Start playing',
    pause: 'Pause',
    fastForward: 'Forward {seektime} seconds',
    seek: 'Search',
    seekLabel: '{currentTime} from {duration}',
    played: 'Played',
    buffered: 'Buffer',
    currentTime: 'Current time',
    duration: 'Duration',
    volume: 'Volume',
    mute: 'Mute',
    unmute: 'Unmute',
    enableCaptions: 'Enable subtitles',
    disableCaptions: 'Turn off subtitles',
    download: 'Download',
    enterFullscreen: 'Full screen mode',
    exitFullscreen: 'Leave full screen mode',
    frameTitle: 'Player for {title}',
    captions: 'Subtitles',
    settings: 'Settings',
    pip: 'PIP',
    menuBack: 'Back',
    speed: 'Speed',
    normal: 'Normal',
    quality: 'Quality',
    loop: 'Loop',
    start: 'Start',
    end: 'End',
    all: 'All',
    reset: 'Reset',
    disabled: 'Disabled',
    enabled: 'Enabled',
    advertisement: 'Advertising',
    selectFragment: 'Select fragment',
    removeSelection: 'Remove selection',
    loadingFragment: 'Fragment loading has started!',
    errorLoadingArchive: 'Error loading archive!',
    tryLater: 'Please try again later or contact support',
    remove: 'Remove',
    upload: 'Download',
    save: 'Save',
    cancel: 'Cancel',
    description: 'Description',
    attention: 'Attention',
    savingFragment: 'Saving a fragment',
    savingFragmentInfo: 'The fragment is being saved and will be added to saved posts shortly',
    failedSavedFragment: 'Failed to save for unknown reason',
    blockedSection: 'It is impossible to export a fragment with a blocked section!',
    toPlay: 'Play',
    errorSetting: 'Player setup error',
    synchroArchive: 'Archive synchronization...',
    loadArchive: 'Loading archive...',
    loading: 'Loading',
    qualityBadge: {
      2160: '4K',
      1440: 'HD',
      1080: 'HD',
      720: 'HD',
      576: 'SD',
      480: 'SD'
    }
  }
}
