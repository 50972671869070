import React, { ReactNode, createContext } from 'react'
import { IPtzData } from '../../PTZ/models'
import { ICameraPresetPayload } from '../models'

interface ProviderValue {
  updatePresetPreview(payload: ICameraPresetPayload): Promise<void>
  createCameraPreset(title: string, status: IPtzData['status']): Promise<void>
  updatePreset(id: string, title?: string): Promise<void>
  removeCameraPreset(id: string): Promise<void>
}

export const PresetCommandContext = createContext<ProviderValue>(null!)
export const PresetCommandProvider = ({
  children,
  value
}: {
  children: ReactNode
  value: ProviderValue
}) => <PresetCommandContext.Provider value={value}>{children}</PresetCommandContext.Provider>
