export function secondsToHms(d: number) {
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)
  return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2)
}

export function range(start: number, stop: number, step: number = 1) {
  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return []
  }

  const result = []
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i)
  }

  return result
}

export function convertToPlayerTime(start: number, time: number, chunkOffset: number) {
  return Math.max(0, (time - start) / 1000 + chunkOffset)
}

export function convertToStoreTime(start: number, playerTime: number, chunkOffset: number) {
  return Math.max(start, start + (playerTime - chunkOffset) * 1000)
}
