import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { StreamInstanceType, StreamStatuses } from '../models'
import { PlayerModes, LivePlayerConfig } from '../../../IWidgetProps'
import { useStreamsFetcher } from '../hooks'

export type ProviderProps =
  | Pick<
      LivePlayerConfig,
      'id' | 'refetch' | 'batchAttributes' | 'defaultStreamId' | 'defaultStreamType'
    > & { type?: string }

interface ProviderValue {
  isLoading: boolean
  lastActiveStreamChangedByUser: React.MutableRefObject<Record<string, any> | null>
  getActiveStream: <T extends PlayerModes>(moduleType: T) => StreamInstanceType<T> | null
  getAllStreams: <T extends PlayerModes>() => Map<string, StreamInstanceType<T>>
  setStreamById: (id: string) => void
  setStreamByType: (type: string) => void
  updateStreamStatus: (status: StreamStatuses) => void
  fetch: (fetchAttempts?: number) => Promise<Record<string, any>[] | undefined>
  refetch: () => Promise<void>
}

export const StreamSwitcherContext = createContext<ProviderValue>(null!)

export const StreamSwitcherProvider = ({
  children,
  value
}: {
  children: ReactNode
  value: ProviderProps
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [streams, setStreams] = useState<Map<string, Record<string, any>>>(new Map())
  const [activeStream, setActiveStream] = useState<Record<string, any> | null>(null)
  const lastActiveStreamChangedByUser = useRef<typeof activeStream>(null!)

  const refetch = value.refetch && {
    retryAfterTime: value.refetch.retryAfterTime || 3000,
    maxRetryCount: value.refetch.maxRetryCount || 3
  }

  const { fetchCameraStreams, fetchRecordOrFileStreamData } = useStreamsFetcher(
    value.id,
    value.type,
    refetch,
    value.batchAttributes
  )

  const getAllStreams = useCallback(<T extends PlayerModes>() => {
    return streams as Map<string, StreamInstanceType<T>>
  }, [streams])

  const defaultStream = useMemo(() => streams.values().next().value, [streams])

  const getActiveStream = useCallback(<T extends PlayerModes>() => {
    return activeStream as StreamInstanceType<T> | null
  }, [activeStream])

  const setStreamById = useCallback((id: string, fallback?: () => void) => {
    const targetStream = streams.get(id)
    if (targetStream) lastActiveStreamChangedByUser.current = targetStream
    targetStream ? setActiveStream(targetStream) : fallback?.()
  }, [streams])

  const setStreamByType = useCallback((type: string, fallback?: () => void) => {
    const targetStream = Array.from(streams.values()).find(({ streamType }) => streamType === type)
    targetStream ? setActiveStream(targetStream) : fallback?.()
  }, [streams])

  const detectActiveStream = () => {
    setStreamById(String(value.defaultStreamId), () =>
      setStreamByType(String(value.defaultStreamType), () => setActiveStream(defaultStream))
    )
  }

  const updateStreamStatus = (status: StreamStatuses) =>
    setActiveStream({ ...activeStream, status })

  const fetchStreamData = async () => {
    setIsLoading(true)
    try {
      if (value.type) {
        const recordStreamData = await fetchRecordOrFileStreamData()
        if (!recordStreamData) return
        setStreams(new Map([[recordStreamData.id, recordStreamData]]))
      } else {
        const response = await fetchCameraStreams()
        if (!response?.length) return
        const mappedStreams = new Map(response.map((stream) => [stream.id as string, stream]))
        setStreams(mappedStreams)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchStreamData()
  }, [value.type, value.id])

  useEffect(() => {
    streams.size && detectActiveStream()
  }, [streams])

  return (
    <StreamSwitcherContext.Provider
      value={{
        isLoading,
        lastActiveStreamChangedByUser,
        getActiveStream,
        getAllStreams,
        setStreamById,
        setStreamByType,
        updateStreamStatus,
        fetch: fetchCameraStreams,
        refetch: fetchStreamData,
      }}>
      {children}
    </StreamSwitcherContext.Provider>
  )
}
