/** @jsx jsx */
import { jsx } from '@emotion/react'

import React, { ChangeEvent, Fragment, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

import { useLocaleContext } from '@src/Root'
import { TProcessKeys, dialogMessage, errorColor, processStateKeys } from './common'
import { PresetHooks } from '../../Presets'
import { ICameraPreset, IPtzData } from '../models'

interface Props {
  preset?: ICameraPreset
  getPtzData: () => Promise<IPtzData | null | false>
  onHide: () => void
}

export const UpdateOrCreateModal = (props: Props) => {
  const { onHide, preset, getPtzData } = props
  const { localize } = useLocaleContext()
  const { refetch } = PresetHooks.usePresetData()
  const { createCameraPreset, updatePreset } = PresetHooks.usePresetCommand()

  const [presetTitle, setPresetTitle] = useState(preset?.title || '')
  const [processState, setProcessState] = useState<TProcessKeys | null>(null)

  const onPresetTitleChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const pattern = /^[A-zA-я0-9 .,-]*$/
    if (pattern.test(e.target.value)) {
      setPresetTitle(e.target.value)
    }
  }

  const handlePTZCreate = async () => {
    try {
      setProcessState(processStateKeys.loading)
      if (preset) {
        await updatePreset(preset.id, presetTitle)
      }

      if (!preset) {
        const ptzData = await getPtzData()
        if (!ptzData) throw new Error('can not get ptz data while try create new preset')
        await createCameraPreset(presetTitle, ptzData.status)
      }
      await refetch()
      setProcessState(null)
      onHide()
    } catch (e) {
      setProcessState(processStateKeys.error)
    }
  }

  const isLoading = processState === processStateKeys.loading
  const isError = processState === processStateKeys.error

  return (
    <Dialog
      visible
      onHide={onHide}
      modal
      closable={false}
      header={preset ? localize('ptzControls.presets.refresh') : localize('ptzControls.presets.create')}
      footer={
        <Fragment>
          <Button
            onClick={handlePTZCreate}
            icon={isLoading ? 'mdi mdi-20x mdi-loading mdi-spin' : undefined}
            disabled={isLoading || isError}
            label={preset ? localize('ptzControls.presets.refreshAction') : localize('ptzControls.presets.createAction')}
          />
          <Button
            disabled={isLoading}
            onClick={onHide}
            className="p-button-secondary p-button-outlined"
            label={localize('ptzControls.presets.cancel')}
          />
        </Fragment>
      }>
      <div className="p-px-3 p-input-filled" css={dialogMessage}>
        <div className="p-field p-d-flex p-flex-column">
          <label htmlFor={'widget-react-player-preset-title-creator'}>{localize('ptzControls.presets.title')}</label>
          <InputText
            id={'widget-react-player-preset-title-creator'}
            type="text"
            value={presetTitle}
            onChange={onPresetTitleChanged}
          />
        </div>
        {isError && <div style={errorColor}>{localize('ptzControls.presets.unexpectedError')}</div>}
      </div>
    </Dialog>
  )
}
