/** @jsx jsx */
import { jsx } from '@emotion/react'

import React from 'react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import { dialogMessage } from './common'
import { useLocaleContext } from '@src/Root'

interface Props {
  onHide: () => void
}

export const AccessDeniedModal = (props: Props) => {
  const { onHide } = props
  const { localize } = useLocaleContext()

  return (
    <Dialog
      visible
      onHide={onHide}
      closable={false}
      footer={
        <Button
          onClick={onHide}
          className="p-button-secondary p-button-outlined"
          label={localize('ptzControls.presets.ok')}
        />
      }
      header={localize('ptzControls.presets.accessDenied')}>
      <div className="p-px-3" css={dialogMessage}>
        <span>{localize('ptzControls.presets.accessDeniedDetail')}</span>
      </div>
    </Dialog>
  )
}
