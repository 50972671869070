import { useContext } from 'react'
import { WidgetPropsContext } from '../providers'
import { PlayerModes, PlayerWidgetPropsProvider } from '../IWidgetProps'
import { WidgetReceivedProps } from '@netvision/lib-widget-renderer'

interface WidgetReceivedPropsWithNode<
  P extends {
    [k: string]: any
  },
  A extends string
> extends WidgetReceivedProps<P, A> {
  domElement: HTMLElement
}
export const useWidgetProps = <T extends PlayerModes>() =>
  useContext(WidgetPropsContext) as unknown as WidgetReceivedPropsWithNode<
    PlayerWidgetPropsProvider<T>,
    ''
  >
