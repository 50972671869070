/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Button } from 'primereact/button'
import { FC, Fragment, useState } from 'react'
import { selectStyles } from './Select.styles'
import { TOOLTIP_OPTIONS } from '../../modules/ControlPanel/controls/helpers'

type SelectType = {
  activeId?: string | number
  icon: string
  disabled?: boolean
  options: Array<{ id: string | number; title: string }>
  secondary?: boolean
  // TODO переименовать id в Value
  onSelect: (option: { id: string | number; title: string }) => void
  tooltip?: string
}

export const Select: FC<SelectType> = ({
  activeId,
  icon,
  disabled = false,
  options,
  secondary = false,
  onSelect,
  tooltip
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div css={selectStyles.wrapper}>
      <Button
        className={secondary ? 'p-button-secondary' : ''}
        css={selectStyles.controlButtonCSS}
        icon={icon}
        disabled={disabled}
        onClick={() => setIsOpen((prev) => !prev)}
        tooltip={tooltip}
        tooltipOptions={TOOLTIP_OPTIONS}
      />
      {isOpen && (
        <Fragment>
          <div css={selectStyles.listOverlay} onClick={() => setIsOpen(false)}></div>
          <ul css={selectStyles.list}>
            {options.map((item: any) => (
              <li
                className="p-menuitem"
                key={item.id}
                onClick={() => {
                  setIsOpen(false)
                  activeId !== item.id && onSelect(item)
                }}
                aria-selected={activeId === item.id}>
                <span>{item.title}</span>
                <i
                  css={
                    activeId === item.id ? selectStyles.itemActiveIcon : selectStyles.itemIconHide
                  }
                  className="mdi mdi-check"
                />
              </li>
            ))}
          </ul>
        </Fragment>
      )}
    </div>
  )
}
