import { ITimeline } from '../IWidgetProps'

export type BreakRange = Omit<Readonly<ITimeline>, 'end'>

const divideRange = (limit: number, r: ITimeline): ITimeline[] => {
  const { start, streamId } = r

  const baseNumber = Math.ceil(start / limit) * limit
  const baseDiff = baseNumber - start
  const n = Math.floor(r.duration! / limit)

  const arr: BreakRange[] = []

  if (baseDiff > 0) {
    arr.push({ start, duration: baseDiff, streamId })
  }

  for (let i = 0; i < n - 1; i++) {
    arr.push({ start: baseNumber + i * limit, duration: limit, streamId })
  }

  const d = r.duration! - (n - 1) * limit - baseDiff
  if (d > limit) {
    arr.push({ start: baseNumber + (n - 1) * limit, duration: limit, streamId })
    arr.push({ start: baseNumber + n * limit, duration: d - limit, streamId })
  } else {
    arr.push({ start: baseNumber + (n - 1) * limit, duration: d, streamId })
  }

  return arr.map((range) => ({ ...range, end: range.start + range.duration! }))
}

export const breakDown = (limit: number, t: ITimeline[]): ITimeline[] => {
  if (limit <= 0) return []

  return t.reduce((acc, v) => {
    if (v.duration! < limit) {
      acc.push(v)
    } else {
      acc.push(...divideRange(limit, v))
    }
    return acc
  }, [] as ITimeline[])
}
