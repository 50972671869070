import { useEffect } from 'react'
import { usePTZApi } from '../../../hooks/usePTZApi'
import { usePTZStore } from '../../../hooks/usePTZStore'

interface Props {
  initialValue?: number
  cameraId: string
}

export const DEFAULT_SENSITIVITY_CONFIG = {
  value: 0.05,
  min: 0.05,
  max: 1,
  step: 0.05
}

export const useSensitivity = (props: Props) => {
  const { cameraId, initialValue } = props
  const { updateSettings } = usePTZApi(cameraId)

  const { ptzSettings, sensitivity, setSensitivity } = usePTZStore()

  useEffect(() => {
    setSensitivity(initialValue || DEFAULT_SENSITIVITY_CONFIG.value)
  }, [setSensitivity, initialValue])

  useEffect(() => {
    if (sensitivity === 0 || !ptzSettings) return
    const likeDebounce = setTimeout(
      () =>
        updateSettings({
          ...ptzSettings,
          sensitivity
        }),
      1200
    )
    return () => clearTimeout(likeDebounce)
  }, [ptzSettings, sensitivity, updateSettings])
}
