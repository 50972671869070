/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/react'
import { ProgressBar } from 'primereact/progressbar'
import { ProgressSpinner } from 'primereact/progressspinner'

const containerCss = css`
  position: absolute;
  top: calc(15rem / var(--bfs));
  left: calc(15rem / var(--bfs));

  .p-progress-spinner {
    pointer-events: none;
    display: block;
    font-size: calc(32rem / var(--bfs));
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);

    svg {
      font-size: inherit;
      width: 1em;
      height: 1em;
      animation: p-progress-spinner-rotate 2s linear infinite;
    }

    svg > circle {
      stroke-width: calc(5rem / var(--bfs));
    }
  }
`

const progressCss = css`
  &.p-progressbar {
    width: 100%;
    height: calc(1rem / var(--bfs));
  }
`

interface Props {
  extraStyle?: SerializedStyles
}

export const Loader = (props: Props) => (
  <div css={[containerCss, props.extraStyle]}>
    <ProgressSpinner />
  </div>
)

export const ProgressLoader = (props: Props) => {
  return <ProgressBar css={[progressCss, props.extraStyle]} mode="indeterminate" />
}
