import { Player } from '@netvision/lib-player'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useWidgetProps } from './useWidgetProps'
import WebWorker from '../workers/webWorkerFactory'
import CanvasBlurWorker from '../workers/canvas-blur-worker'

export const useBlurredBackground = (disable?: boolean) => {
  const { props: widgetProps } = useWidgetProps()
  const rafTimer = useRef<number | undefined>()
  const lastTimestamp = useRef<number>(0)
  const canvasElementRef = useRef<HTMLCanvasElement>(null!)
  const offscreenCanvasRef = useRef<OffscreenCanvas>(null!)
  const [playerInstance, setPlayerInstance] = useState<Player>()
  const [videoElement, setVideoElement] = useState<HTMLVideoElement>()
  const isBlurDisabled = !!disable || !!widgetProps?.disableBlur

  const worker = useMemo(() => {
    return new WebWorker(CanvasBlurWorker) as Worker
  }, [])

  // worker events and destroy
  useEffect(() => {
    const abrController = new AbortController()
    const signal = abrController.signal
    worker.addEventListener('message', (e) => {}, { signal })

    return () => {
      abrController.abort()
      worker.terminate()
    }
  }, [worker])

  const createCanvasElement = useCallback((video: HTMLVideoElement) => {
    const canvas = document.createElement('canvas')
    canvas.width = video.offsetWidth
    canvas.height = video.offsetHeight
    canvas.style.position = 'absolute'
    canvas.style.left = '0'
    canvas.style.top = '0'
    canvas.style.width = '100%'
    canvas.style.height = '100%'
    canvas.style.filter = 'blur(30px)'

    return canvas
  }, [])

  const getFrameFromVideoAndSendToWorker = useCallback(
    (videoElement: HTMLVideoElement) => {
      if (!videoElement || !videoElement?.videoWidth || !videoElement?.videoHeight) return
      createImageBitmap(videoElement)
        .then((imageBitmap) => {
          worker.postMessage({ action: 'render', imageBitmap }, [imageBitmap])
        })
        .catch(() => {})
    },
    [worker]
  )

  const processFrame = useCallback(
    (timestamp: number) => {
      if (!videoElement) return
      const fps = 30
      const timeBetweenFrames = 1000 / fps

      rafTimer.current = requestAnimationFrame(processFrame)

      if (timestamp - lastTimestamp.current < timeBetweenFrames) return
      lastTimestamp.current = timestamp

      getFrameFromVideoAndSendToWorker(videoElement)
    },
    [videoElement, getFrameFromVideoAndSendToWorker]
  )

  // Start the animation
  useEffect(() => {
    if (!isBlurDisabled) {
      if (!playerInstance?.bridgeApi?.on || !videoElement) return
      // Create canvas if it doesn't exist
      if (!canvasElementRef.current && videoElement) {
        canvasElementRef.current = createCanvasElement(videoElement)
      }
      // Append canvas to DOM before video element wrapper
      const plyrContainer = videoElement?.parentElement
      canvasElementRef.current && plyrContainer?.before(canvasElementRef.current)

      // Initialize offset canvas for sending to worker
      if (!offscreenCanvasRef.current) {
        offscreenCanvasRef.current = canvasElementRef.current.transferControlToOffscreen()
        worker.postMessage({ action: 'init', canvas: offscreenCanvasRef.current }, [
          offscreenCanvasRef.current
        ])
      }

      const isPlaying = playerInstance.bridgeApi.playing
      if (isPlaying) {
        rafTimer.current = requestAnimationFrame(processFrame)
      } else {
        getFrameFromVideoAndSendToWorker(videoElement)
      }

      playerInstance.bridgeApi.on('seeked', () => {
        getFrameFromVideoAndSendToWorker(videoElement)
      })

      playerInstance.bridgeApi.on('play', () => {
        rafTimer.current = requestAnimationFrame(processFrame)
      })

      playerInstance.bridgeApi.on('pause', () => {
        if (rafTimer.current) cancelAnimationFrame(rafTimer.current)
      })
    }

    return () => {
      rafTimer.current && cancelAnimationFrame(rafTimer.current)
      canvasElementRef.current?.remove()
    }
  }, [
    playerInstance,
    playerInstance?.bridgeApi,
    videoElement,
    createCanvasElement,
    isBlurDisabled,
    worker,
    processFrame,
    getFrameFromVideoAndSendToWorker
  ])

  const updatePlayerInstance = useCallback((player: Player, videoElement: HTMLVideoElement) => {
    setPlayerInstance(player)
    setVideoElement(videoElement)
  }, [])

  return { updatePlayerInstance }
}
