import React, { ReactNode, createContext, memo, useMemo } from 'react'

import { PresetCommandProvider } from '.'
import { usePresetsApis } from '../hooks'
import { ICameraPreset } from '../models'
import { PresetStore } from '../store/preset-store'

interface ProviderValue {
  isLoading: boolean
  refetch: () => Promise<void>
  presets: ICameraPreset[]
  selectedPreset: ICameraPreset | null
  setSelectedPreset: (preset: ICameraPreset | null) => void
}

interface Props {
  cameraId: string
  children: ReactNode
}

export const PresetDataContext = createContext<ProviderValue>(null!)

const PresetDataProvider = (props: Props) => {
  const { children, cameraId } = props
  const {
    createCameraPreset,
    getCameraPresets,
    removeCameraPreset,
    updatePreset,
    updatePresetPreview
  } = usePresetsApis(cameraId)

  const presetCommands = useMemo(
    () => ({
      createCameraPreset,
      removeCameraPreset,
      updatePreset,
      updatePresetPreview
    }),
    [createCameraPreset, removeCameraPreset, updatePreset, updatePresetPreview]
  )

  const presetStore = useMemo(() => new PresetStore(getCameraPresets), [])

  return (
    <PresetDataContext.Provider value={presetStore}>
      <PresetCommandProvider value={presetCommands}>{children}</PresetCommandProvider>
    </PresetDataContext.Provider>
  )
}

const PresetDataProviderMemo = memo(PresetDataProvider)

export { PresetDataProviderMemo as PresetDataProvider }
