import { action, observable } from 'mobx'
import { IPtzSettings } from '../models'

export class PTZStore {
  @observable isPTZBlock = false
  @observable isPTZPanelOpen = false
  @observable isPTZSupport: boolean | undefined = undefined
  @observable isPTZConnectionError = false

  @observable isOpenConnectionModal = false
  @observable isOpenBTZBlockModal = false
  @observable activeAnalyticsCount = -1

  @observable isMoving = false
  @observable sensitivity = 0

  @observable ptzSettings: IPtzSettings

  @action.bound
  setMoving(flag: boolean) {
    this.isMoving = flag
  }

  @action.bound
  setSensitivity(sensitivity: number) {
    this.sensitivity = sensitivity
  }

  @action.bound
  setPTZSettings(ptzSettings: IPtzSettings) {
    this.ptzSettings = ptzSettings
  }

  @action.bound
  togglePTZPanel(flag: boolean) {
    this.isPTZPanelOpen = flag
  }

  @action.bound
  setIsPTZSupport(flag: boolean) {
    this.isPTZSupport = flag
  }

  @action.bound
  setIsPTZBlock(flag: boolean) {
    this.isPTZBlock = flag
    if (flag) this.isPTZPanelOpen = false
  }

  @action.bound
  setIsPTZConnectionError(flag: boolean) {
    this.isPTZConnectionError = flag
  }

  @action.bound
  setActiveAnalyticsCount(count: number) {
    this.activeAnalyticsCount = count
  }

  @action.bound
  toggleConnectionModal(flag: boolean) {
    this.isOpenConnectionModal = flag
  }

  @action.bound
  toggleBlockedModal(flag: boolean) {
    this.isOpenBTZBlockModal = flag
  }
}
