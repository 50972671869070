import { RefObject, useEffect, useMemo, useState } from 'react'
import { debounce } from '../../../utils'

export interface ISizeAdjustments {
  width: number
  height: number
  left: number
  top: number
  scale: number
}

const initialSize = {
  width: 0,
  height: 0,
  left: 0,
  top: 0,
  scale: 1
}

function calculateNaturalSizeAdjustments(
  containerHeight: number,
  containerWidth: number,
  naturalHeight: number,
  naturalWidth: number
): ISizeAdjustments {
  const heightZoom = containerHeight / naturalHeight
  const widthZoom = containerWidth / naturalWidth
  let height
  let width
  let scale
  let topOffset = 0
  let leftOffset = 0
  if (heightZoom.toFixed(2) === widthZoom.toFixed(2)) {
    height = containerHeight
    width = containerWidth
    scale = widthZoom
  } else if (heightZoom > widthZoom) {
    const clientActualHeight = widthZoom * naturalHeight
    topOffset = (containerHeight - clientActualHeight) / 2
    height = clientActualHeight
    width = containerWidth
    scale = widthZoom
  } else {
    const clientActualWidth = heightZoom * naturalWidth
    leftOffset = (containerWidth - clientActualWidth) / 2
    height = containerHeight
    width = clientActualWidth
    scale = heightZoom
  }
  return {
    width,
    height,
    scale,
    left: leftOffset,
    top: topOffset
  }
}

export function useAdjustToNaturalSize<R extends HTMLElement>(
  overlayBaseNodeRef: RefObject<R>,
  naturalHeight: number,
  naturalWidth: number
): ISizeAdjustments {
  const [sizeOfSelectArea, setSizeOfSelectArea] = useState<ISizeAdjustments>(initialSize)

  const updateResults = useMemo(
    () =>
      debounce(
        (overlayBaseNode: R) =>
          setSizeOfSelectArea(
            calculateNaturalSizeAdjustments(
              overlayBaseNode.clientHeight,
              overlayBaseNode.clientWidth,
              naturalHeight,
              naturalWidth
            )
          ),
        300
      ),
    [naturalHeight, naturalWidth]
  )

  useEffect(() => {
    const overlayBaseNode = overlayBaseNodeRef.current
    if (!overlayBaseNode) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target !== overlayBaseNode) return
        requestAnimationFrame(() => updateResults(overlayBaseNode))
      }
    })

    resizeObserver.observe(overlayBaseNode)

    return () => {
      resizeObserver.disconnect()
      setSizeOfSelectArea(initialSize)
    }
  }, [overlayBaseNodeRef, updateResults])
  return sizeOfSelectArea
}
