import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react'
import { PlayerModes } from '../IWidgetProps'
import { useWidgetProps } from '@src/hooks'

export const CameraTimeOffsetContext = createContext<{ cameraTimeOffset: number | null }>({
  cameraTimeOffset: null
})

export const CameraTimeOffsetProvider = ({ children }: { children: ReactNode }) => {
  const { props: widgetProps } = useWidgetProps<PlayerModes.MultiMode>()
  const [offset, setOffset] = useState<null | number>(null)
  const { features } = widgetProps!

  const fetchTimeFromServer = useCallback(async (url: string): Promise<number | void> => {
    try {
      const response = await fetch(url)
      const { timestamp } = await response.json()
      return timestamp
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error)
    }
  }, [])

  useEffect(() => {
    if (!features?.timezoneSettings?.isActive) return

    const startTime = performance.now() // Начало измерения времени
    fetchTimeFromServer('/gateway/cameras/serverTime').then((serverTime) => {
      if (serverTime) {
        // Применение таймзоны к серверному времени
        const serverTimestampWithTimezone = new Date(
          new Date(serverTime).toLocaleString('en-US', {
            timeZone: features.timezoneSettings?.timezone || 'UTC'
          })
        ).getTime()

        // Текущее время в мс
        const currentTimestamp = Date.now()
        // Конец измерения времени
        const endTime = performance.now()
        // Время выполнения запроса и преобразований даты в миллисекундах
        const duration = endTime - startTime

        // Разница между временем на клиенте и временем на сервере
        const offsetValue =
          new Date(currentTimestamp - serverTimestampWithTimezone).getTime() - duration

        setOffset(offsetValue)
      }
    })
  }, [fetchTimeFromServer, features?.timezoneSettings?.isActive, features?.timezoneSettings?.timezone])

  return (
    <CameraTimeOffsetContext.Provider value={{ cameraTimeOffset: offset }}>
      {children}
    </CameraTimeOffsetContext.Provider>
  )
}
