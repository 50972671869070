import { PlayerModes } from '../../../IWidgetProps'

export enum StreamStatuses {
  Error,
  Success,
  Pending
}

interface StreamTypeMap {
  [PlayerModes.Live]: ILiveStream
  [PlayerModes.Archive]: IArchiveStream
  [PlayerModes.Saved]: IRecordOrFileStream
}

export type StreamInstanceType<T extends PlayerModes> = T extends keyof StreamTypeMap
  ? StreamTypeMap[T]
  : never

interface IStreamBasic {
  id: string
  type: 'Stream'
  title: string
  cameraId: string
  orderIndex: number
  status?: StreamStatuses
}

interface ILiveStream extends IStreamBasic {
  wsStreamUrl: string
  snapshotStreamUrl: string
  streamType?: string
}

interface IArchiveStream extends IStreamBasic {
  dvrTimelines: { start: number; end: number; duration: number }[]
  exportStreamUrl?: string
  hlsStreamUrl: string
  externalSystemName: string
  snapshotStreamUrl?: string
}

interface IRecordOrFileStream extends IStreamBasic {
  dvrTimelines: { start: number; end: number; duration: number }[]
  duration: number
  exportStreamUrl?: string
  snapshotUrl: string
  streamUrl: string
  streamType?: string
  externalSystemName: string
}

export type IStream<T extends PlayerModes> = T extends PlayerModes.Archive
  ? IArchiveStream
  : T extends PlayerModes.Live
  ? ILiveStream
  : T extends PlayerModes.Saved
  ? IRecordOrFileStream
  : never
