import { css } from '@emotion/react'

export const selectStyles = {
  wrapper: css`
    position: relative;
  `,
  listOverlay: css`
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  list: css`
    list-style: none;
    margin: 0;
    padding: 20px;
    z-index: 3000;
    width: max-content;
    position: absolute;
    top: -5px;
    left: 50;
    transform: translate(-50%, -100%);

    background: var(--bg-gradient);
    border-radius: var(--border-radius);
    overflow: hidden;

    box-shadow: var(--shadow-raised);

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      color: var(--text-color-secondary);
      transition: color 200ms ease;
      min-width: 70px;
      max-width: 350px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      font-size: 1rem;
      text-align: left;
      font-weight: 500;

      :hover {
        color: var(--text-color);
      }
      :focus {
        color: var(--text-color);
      }
    }

    li[aria-selected='true'] {
      color: var(--text-color);
    }
  `,
  controlButtonCSS: css`
    font-size: 26px;
    background-color: transparent;
    border: 0;

    .mdi {
      transition: color var(--transition-duration);
    }

    &:hover:not(:disabled) {
      background-color: transparent !important;

      .mdi {
        color: var(--primary-color);
        transition: color var(--transition-duration);
      }
    }

    &.p-button-secondary.p-button.p-component.p-button-icon-only {
      width: 48px;
      height: 40px;
      border-radius: 10px;
      font-size: 20px;
      background: var(--bg-gradient-dark);
      border: 0;

      span:first-child {
        color: var(--text-color-secondary);
      }

      &:hover:not(:disabled) {
        span:first-child {
          color: var(--primary-color);
        }
      }
    }
  `,
  itemActiveIcon: css`
    color: var(--text-color);
    margin-left: 10px;
  `,
  itemIconHide: css`
    color: transparent;
    margin-left: 10px;
  `
}
