import { useApiRepo } from '../../../hooks'
import { ICameraPreset, ICameraPresetPayload } from '../models'
import { createUUID4 } from '../../../utils'
import { IPtzData } from '../../PTZ/models'

export const usePresetsApis = (cameraId: string) => {
  const api = useApiRepo()

  const createCameraPreset = async (title: string, status: IPtzData['status']) => {
    await api.createEntity({
      id: createUUID4(),
      type: 'CameraPreset',
      cameraId,
      title,
      groundCalibration: {},
      status
    })
  }

  // TODO: Не забыть дать задачу бэку исправить подписку на обновление
  // по сокетам, чтобы не пришлось менять название title (см 7596)
  const updatePresetPreview = async ({
    id,
    title,
    positions,
    streamId
  }: ICameraPresetPayload) => {
    const body = {
      id,
      title,
      type: 'CameraPreset',
      updatePreviewCommand: { ...positions },
      streamId
    }

    if (!streamId) delete body.streamId
    await api.updateEntity(body)
  }

  const updatePreset = async (id: string, title: string) => {
    await api.updateEntity({
      id,
      type: 'CameraPreset',
      title
    })
  }

  const getCameraPresets = async () => {
    const { results } = await api.getEntitiesList<ICameraPreset>({
      limiter: {
        type: 'CameraPreset'
      },
      filter: {
        q: `cameraId==${cameraId}`
      }
    })
    return results
  }

  const removeCameraPreset = async (id: string) => {
    await api.deleteEntity({
      id,
      type: 'CameraPreset'
    })
  }

  return {
    getCameraPresets,
    createCameraPreset,
    removeCameraPreset,
    updatePreset,
    updatePresetPreview
  }
}
