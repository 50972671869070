import { useGetPTZData, usePTZApi, usePTZStore } from '.'
import { IMoveCommands, IPtzData, IPtzSettings, IPtzValues } from '../models'

const REQUEST_SETTING = { enable: false }

export const usePTZCommands = (cameraId: string, ptzSettings: IPtzSettings): IMoveCommands => {
  const { request: PTZDataRequest } = useGetPTZData(cameraId, REQUEST_SETTING)
  const { absoluteMove, relativeMove, stop, continuousMove } = usePTZApi(cameraId)
  const { sensitivity: sensitivitySettings, setMoving } = usePTZStore()

  const offset = sensitivitySettings

  const moveContinuously = async (moveData: { x?: number; y?: number; zoom?: number }) => {
    await continuousMove(moveData, ptzSettings)
  }

  const absoluteZoom = async (zoom: number) => {
    try {
      setMoving(true)
      const ptzData = await PTZDataRequest()
      if (!ptzData) {
        console.error('Can not make absoluteZoom. Because ptzData is not defined')
        return
      }
      await absoluteMove({
        ...ptzData.status.position,
        zoom
      })
    } catch (e) {
      console.error(e)
    } finally {
      setMoving(false)
    }
  }

  const pointZoom = async (transform: (absolute: IPtzData) => IPtzValues) => {
    try {
      setMoving(true)
      const ptzData = await PTZDataRequest()
      if (!ptzData) {
        console.error('Can not make pointZoom. Because ptzData is not defined')
        return
      }
      await relativeMove(transform(ptzData))
    } catch (e) {
      console.error(e)
    } finally {
      setMoving(false)
    }
  }

  return {
    stop,
    absoluteMove,
    absoluteZoom,
    pointZoom,
    moveLeft() {
      return moveContinuously({
        x: -offset
      })
    },
    moveRight() {
      return moveContinuously({
        x: offset
      })
    },
    moveUp() {
      return moveContinuously({
        y: offset
      })
    },
    moveDown() {
      return moveContinuously({
        y: -offset
      })
    },
    moveUpLeft() {
      return moveContinuously({
        x: -offset,
        y: offset
      })
    },
    moveUpRight() {
      return moveContinuously({
        x: offset,
        y: offset
      })
    },
    moveDownLeft() {
      return moveContinuously({
        x: -offset,
        y: -offset
      })
    },
    moveDownRight() {
      return moveContinuously({
        x: offset,
        y: -offset
      })
    },
    zoomIn() {
      return moveContinuously({
        zoom: offset
      })
    },
    zoomOut() {
      return moveContinuously({
        zoom: -offset
      })
    }
  }
}
