export const TOOLTIP_OPTIONS = {
  position: 'top'
}

const format = (value: number) => `0${value}`.slice(-2)
const getHours = (value: number) => Math.floor((value / 60 / 60) % 60)
const getMinutes = (value: number) => Math.floor((value / 60) % 60)
const getSeconds = (value: number) => Math.floor(value % 60)

// only interval seconds
// like time=10seconds not Date.now or something like that
export function formatTime(time: number, displayHours = false, inverted = false) {
  const hours = getHours(time)
  const mins = getMinutes(time)
  const secs = getSeconds(time)

  let hoursString = ''
  if (displayHours || hours > 0) {
    hoursString = `${hours}:`
  }

  return `${inverted && time > 0 ? '-' : ''}${hoursString}${format(mins)}:${format(secs)}`
}

export const currentRangeIndex = (ranges: { duration: number }[], currentTime: number) => {
  let index = 0
  let rest = currentTime
  while (index < ranges.length - 1) {
    rest = rest - ranges[index].duration
    if (rest <= 0) {
      break
    }
    index++
  }
  return index
}

export const durationSum = (ranges: { duration: number }[], index: number) => {
  if (index >= ranges.length) {
    return 0
  }
  let sum = 0
  let current = 0
  while (current < index) {
    sum += ranges[current].duration
    current++
  }
  return sum
}
