import { useApiRepo } from '../../../hooks'
import { ProviderProps } from '../providers/stream-switcher-provider'

export function useStreamsFetcher(
  id: string,
  type?: string,
  refetch?: Required<ProviderProps['refetch']>,
  batchAttrs?: string[]
) {
  const api = useApiRepo()

  const delayedRefetch = (fetchAttempts: number, delay: number) => {
    setTimeout(() => fetchCameraStreams(fetchAttempts - 1), delay)
  }

  const fetchCameraStreams = async (fetchAttempts = refetch?.maxRetryCount) => {
    const hasGlobalBatch = 'getEntitiesWithGlobalBatch' in api
    const hasCubeEntities = 'cubeGetEntities' in api

    if (!hasCubeEntities || !api?.cubeGetEntities || !hasGlobalBatch) {
      throw new Error(
        "getEntitiesWithGlobalBatch or cubeGetEntities api methods aren't implemented"
      )
    }

    if (refetch && fetchAttempts && fetchAttempts > refetch.maxRetryCount) {
      fetchAttempts = refetch.maxRetryCount
    }

    try {
      const { results } = await api.cubeGetEntities<{ id: string }>({
        dimensions: ['Stream.id'],
        filters: [
          {
            member: 'Stream.cameraId',
            operator: 'equals',
            values: [id]
          }
        ]
      })

      if (!results.length) {
        if (refetch && fetchAttempts) {
          delayedRefetch(fetchAttempts, refetch.retryAfterTime)
        }
        return
      }

      const fetchedStreams = (await Promise.all(
        results.map(
          async (stream) =>
            // В api-repo закинул фикс, чтобы getEntitiesWithGlobalBatch принимал дженерик.
            await api.getEntitiesWithGlobalBatch(
              { type: 'Stream', id: stream.id },
              [
                'title',
                'cameraId',
                'wsStreamUrl',
                'dvrTimelines',
                'externalSystemName',
                'snapshotStreamUrl',
                'exportStreamUrl',
                'hlsStreamUrl',
                'streamType',
                'orderIndex',
                'duration',
                ...(batchAttrs || [])
              ],
              'react-player'
            )
        )
      )) as Record<string, any>[]

      return fetchedStreams.sort((a, b) => a.orderIndex - b.orderIndex)
    } catch (error) {
      if (!refetch || !fetchAttempts) throw error
      delayedRefetch(fetchAttempts, refetch.retryAfterTime)
      return undefined
    }
  }

  const fetchRecordOrFileStreamData = async (fetchAttempts = refetch?.maxRetryCount) => {
    const hasGlobalBatch = 'getEntitiesWithGlobalBatch' in api

    if (!hasGlobalBatch) {
      throw new Error(
        "getEntitiesWithGlobalBatch or cubeGetEntities api methods aren't implemented"
      )
    }

    if (refetch && fetchAttempts && fetchAttempts > refetch.maxRetryCount) {
      fetchAttempts = refetch.maxRetryCount
    }

    try {
      return await api.getEntitiesWithGlobalBatch(
        { id, type: type || 'Record' },
        ['streamUrl', 'snapshotUrl', 'snapshotStreamUrl', 'title'],
        'player-saved'
      ) as Record<string, any>
    } catch (error) {
      if (!refetch || !fetchAttempts) throw error
      return undefined
    }
  }

  return { fetchCameraStreams, fetchRecordOrFileStreamData }
}
