/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useTooltip } from '../../../hooks'
import { observer } from 'mobx-react-lite'
import { Button } from 'primereact/button'
import { TOOLTIP_OPTIONS } from './helpers'
import { useLocaleContext } from '@src/Root'

interface Props {
  id: string
  isPlaying: boolean
  onClick(flag: boolean): void
  disabled?: boolean
  secondary?: boolean
}

const buttonStyles = css`
  &.p-button.p-component {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: transparent;
    border: 3px solid white;
    box-sizing: border-box;
    padding: 0;
    transition: border-width 0.1s ease-in;

    &:hover {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    span:first-child {
      font-size: 16px;
    }
  }

  &.p-button.p-component.is-stop {
    border-width: 0;

    span:first-child {
      font-size: 26px;
    }
  }

  &.p-button-secondary.p-button.p-component {
    position: relative;
    width: 60px;
    height: 48px;
    border-radius: 10px;
    background: var(--bg-gradient-dark);
    border: 0;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: var(--primary-color);
      filter: blur(7px); 
    }

    span:first-child {
      z-index: 1;
    }
  }
`

export const PlayPauseButton = observer((props: Props) => {
  const { localize } = useLocaleContext()
  // const { isPlaying } = usePlayerStore()
  const isPlaying = props.isPlaying
  const { showTooltip } = useTooltip()

  const handleClick = () => props.onClick(isPlaying)

  const iconClassByStatus = !isPlaying ? 'mdi-play' : 'mdi-pause'
  let buttonExtraClassName = isPlaying ? 'is-stop' : ''
  if (props.secondary) buttonExtraClassName += ' p-button-secondary'
  return (
    <Button
      id={`play-button-${props.id}`}
      disabled={props.disabled}
      onClick={handleClick}
      className={buttonExtraClassName}
      css={buttonStyles}
      icon={`mdi mdi-32px ${iconClassByStatus}`}
      // Do not use tooltip property because of TooltipProvider fullscreen-related functionality
      // Use onMouseEnter event handler instead
      onMouseEnter={() =>
        showTooltip({
          elementId: `play-button-${props.id}`,
          message: !isPlaying ? localize('controls.play') : localize('controls.pause'),
          config: TOOLTIP_OPTIONS
        })
      }
    />
  )
})
