import { useEffect } from 'react'

const STYLE_ID = 'player-style'
const styles =
  '.camera-preview .single-spa-parcel-container, .player-preview .single-spa-parcel-container { width: 100%; height: 100%; }'
export const useAppendSystemStyle = () => {
  useEffect(() => {
    if (document.querySelector(`#${STYLE_ID}`)) return
    const style = document.createElement('style')
    style.id = STYLE_ID
    style.type = 'text/css'
    style.appendChild(document.createTextNode(styles))
    document.head.appendChild(style)
  }, [])
}
