import React, { Suspense } from 'react'
import { useWidgetProps } from '../../hooks'
import { PlayerModes } from '../../IWidgetProps'

const LiveModule = React.lazy(() => import('../../modules/Live'))
const SavedModule = React.lazy(() => import('../../modules/Saved'))
const ArchiveModule = React.lazy(() => import('../../modules/Archive'))
const MultiModeModule = React.lazy(() => import('../../modules/MultiMode'))

export const WidgetAdapter = () => {
  const { props } = useWidgetProps()
  const { playerMode } = props!

  return (
    <Suspense fallback={null}>
      {playerMode === PlayerModes.Live && <LiveModule />}
      {playerMode === PlayerModes.Saved && <SavedModule />}
      {playerMode === PlayerModes.Archive && <ArchiveModule />}
      {playerMode === PlayerModes.MultiMode && <MultiModeModule />}
    </Suspense>
  )
}
