let offscreenCanvas: OffscreenCanvas | null = null
let offscreenCtx: OffscreenCanvasRenderingContext2D | null = null

export default () => {
  self.addEventListener('message', (e) => {
    const {
      action,
      canvas,
      imageBitmap
    }: { action: 'init' | 'render'; canvas: OffscreenCanvas; imageBitmap: ImageBitmap } = e.data

    switch (action) {
      case 'init':
        offscreenCanvas = canvas
        offscreenCtx = canvas.getContext('2d') as OffscreenCanvasRenderingContext2D
        break
      case 'render': {
        if (!offscreenCanvas) return
        const { width = 0, height = 0 } = offscreenCanvas
        offscreenCtx?.drawImage(imageBitmap, 0, 0, width, height)
        break
      }
      default:
        console.warn(`Для этого action - ${action} нет действий`)
    }
  })
}
