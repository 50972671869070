import React, { ReactNode, createContext, useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Toast } from 'primereact/toast'
import { usePlayerStore } from '../hooks'
import { reaction } from 'mobx'

export const ToastContext = createContext<React.MutableRefObject<Toast>>(null!)

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [portalElement, setPortalElement] = useState<Element>(document.body)
  const store = usePlayerStore()
  const toastRef = useRef<Toast>(null!)

  useEffect(() => {
    return reaction(
      () => store.isFullscreen,
      (flag) => setPortalElement(flag ? document.fullscreenElement! : document.body)
    )
  }, [store])

  return (
    <ToastContext.Provider value={toastRef}>
      {children}
      {createPortal(<Toast ref={toastRef} position="bottom-left" />, portalElement)}
    </ToastContext.Provider>
  )
}
