export const getMaxLimitCoords = (config: {
  panelWidth: number
  panelHeight: number
  containerHeight: number
  containerWidth: number
  styleOffsetX: number
  styleOffsetY: number
  deltaX: number
}) => {
  const {
    containerHeight,
    containerWidth,
    panelHeight,
    panelWidth,
    styleOffsetX,
    styleOffsetY,
    deltaX
  } = config

  const minLimitX = -(containerWidth - panelWidth - deltaX - styleOffsetX)
  const maxLimitX = panelWidth + styleOffsetX - panelWidth
  const minLimitY = -(containerHeight - panelHeight - styleOffsetY)
  const maxLimitY = panelHeight + styleOffsetY - panelHeight
  return {
    maxLimitX,
    minLimitY,
    maxLimitY,
    minLimitX
  }
}

export const getInitialStyleOffset = (panel: HTMLElement) => {
  const panelInitialAbsoluteLeftPositionX = Number(
    window.getComputedStyle(panel).right.replace('px', '')
  )
  const panelInitialAbsoluteLeftPositionY = Number(
    window.getComputedStyle(panel).bottom.replace('px', '')
  )
  return {
    panelInitialAbsoluteLeftPositionX,
    panelInitialAbsoluteLeftPositionY
  }
}
