/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Button } from 'primereact/button'
import { useTooltip } from '../../../hooks'
import { useLocaleContext } from '@src/Root'

import { TOOLTIP_OPTIONS } from './helpers'
import { baseButtonStyle } from './styles'

interface Props {
  id: string
  isFullscreen: boolean
  secondary?: boolean
  disabled?: boolean
  onClick(): void
}

export const FullscreenToggle = (props: Props) => {
  const { localize } = useLocaleContext()
  const { showTooltip } = useTooltip()
  const isFullscreen = props.isFullscreen
  const iconClassByStatus = isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'

  let extraClassName = ''
  if (props.secondary) extraClassName += 'p-button-secondary'

  return (
    <Button
      id={`fullscreen-button-${props.id}`}
      disabled={props.disabled}
      className={extraClassName}
      onClick={props.onClick}
      css={baseButtonStyle}
      icon={`mdi mdi-32px ${iconClassByStatus}`}
      // Do not use tooltip property because of TooltipProvider fullscreen-related functionality
      // Use onMouseEnter event handler instead
      onMouseEnter={() =>
        showTooltip({
          elementId: `fullscreen-button-${props.id}`,
          message: localize('controls.enterFullscreen'),
          config: TOOLTIP_OPTIONS
        })
      }
    />
  )
}
