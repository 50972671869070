import React from 'react'
import { configure } from 'mobx'
import { IWidgetProps } from './IWidgetProps'
import { WidgetAdapter } from './modules'
import {
  CameraTimeOffsetProvider,
  LibApiRepoProvider,
  PlayerStoreProvider,
  ToastProvider,
  TooltipProvider,
  WidgetPropsProvider
} from './providers'
import { FeaturesProvider } from './features'
import { useAppendSystemStyle } from './hooks'
// import { LocaleProvider, useLocaleContext } from '@src/Root'
// import { dictionary } from './locales/'
import { useLocale, LocaleProvider } from './providers/locale-provider'

// export { useLocaleContext }
// export { LocaleContext }

export { useLocale as useLocaleContext }

configure({ isolateGlobalState: true })

const Root = (widgetProps: IWidgetProps) => {
  useAppendSystemStyle()

  return (
    <WidgetPropsProvider value={widgetProps}>
      {/* <LocaleProvider dictionary={dictionary}> */}
      <LocaleProvider>
        <LibApiRepoProvider>
          <PlayerStoreProvider>
            <FeaturesProvider>
              <CameraTimeOffsetProvider>
                <ToastProvider>
                  <TooltipProvider>
                    <WidgetAdapter />
                  </TooltipProvider>
                </ToastProvider>
              </CameraTimeOffsetProvider>
            </FeaturesProvider>
          </PlayerStoreProvider>
        </LibApiRepoProvider>
      </LocaleProvider>
    </WidgetPropsProvider>
  )
}

export default Root
