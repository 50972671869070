import { action, observable } from 'mobx'
import { ICameraPreset } from '../models'

export class PresetStore {
    @observable isLoading: boolean = false
    @observable presets: ICameraPreset[] = []
    @observable selectedPreset: ICameraPreset | null
    @observable refetch: () => Promise<void>

    constructor(getCameraPresets: () => Promise<ICameraPreset[]>) {
        this.selectedPreset = null
        this.refetch = async () => {
            try {
              this.isLoading = true
              this.presets = await getCameraPresets()
              this.selectedPreset = this.presets[0] ?? null
            } catch (e) {
              console.error(e)
              throw new Error('Failed to fetch getCameraPresets')
            } finally {
                this.isLoading = false
            }
          }
    }

    @action.bound
    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }

    @action.bound
    setPresets(presets: ICameraPreset[]) {
        this.presets = presets
    }

    @action.bound
    setSelectedPreset(newPreset: ICameraPreset | null) {
        this.selectedPreset = newPreset
    }
}
