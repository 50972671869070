import React from 'react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import { useLocaleContext } from '@src/Root'

type PTZBlockedProps = {
  onHide: () => void
}

export const PTZBlockedModal = ({ onHide }: PTZBlockedProps) => {
  const { localize } = useLocaleContext()

  return (
    <Dialog
      closable
      onHide={onHide}
      visible
      header={localize('ptzControls.blocked.header')}
      footer={
        <Button onClick={onHide} className="p-button-secondary p-button-outlined" label={localize('ptzControls.close')} />
      }>
      <div className="p-px-3">{localize('ptzControls.blocked.message')}</div>
    </Dialog>
  )
}
