import { useEffect, useState } from 'react'
import { useKeyDown } from '../../../hooks'

export function useShowDebugControls() {
  const [visible, setVisible] = useState(false)
  const { KEY_CODES, keyPressed } = useKeyDown()

  useEffect(() => {
    const isPressedDebugShortCut = [KEY_CODES.KeyP, KEY_CODES.KeyT, KEY_CODES.KeyZ]
    if (!isPressedDebugShortCut.every((key) => keyPressed.has(key))) return
    setVisible((prev) => !prev)
  }, [keyPressed])

  return visible
}
