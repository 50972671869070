/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useWidgetProps } from '../../hooks'
import { PlayerModes } from '../../IWidgetProps'

interface Props {
  loading?: boolean
  msg?: string
}

const statusCss = css`
  position: relative;
  background: var(--player-video-bg);
`

const overlayCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-weight: 500;
  color: var(--player-text-color);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .p-progress-spinner-svg {
    width: calc(60rem / var(--bfs));
    height: calc(60rem / var(--bfs));
  }
`

export const StatusInfo = (props: React.PropsWithChildren<Props>) => {
  const { loading, children, msg } = props

  const { props: widgetProps } = useWidgetProps<PlayerModes.Archive>()
  const { width = '100%', height = '100%' } = widgetProps!

  return (
    <div css={statusCss} style={{ height, width }}>
      <div css={overlayCss}>
        {loading && <ProgressSpinner />}
        {msg && <div className="overlay-msg">{msg}</div>}
      </div>
      {children}
    </div>
  )
}
