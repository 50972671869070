/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useState } from 'react'
import { Loader } from '../../../../components'
import { LockButtonAdapter } from '../../widget-adapters'
import { PlayerModes } from '@src/IWidgetProps'
import { useWidgetProps } from '@src/hooks'
import { isEqual, isMatchWith } from 'lodash-es'

const wrapperStyles = css`
  position: relative;
  height: 36px;

  &.is-loading {
    opacity: 0.4;
  }

  &.is-disabled {
    opacity: 0.4;
  }
`

const loaderStyles = css`
  opacity: 0.4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .p-progress-spinner {
    font-size: calc(26rem / var(--bfs));
  }
`

export const LockingButton = ({
  loading,
  cameraId,
  extraClassNames,
  disabled,
  hideTimer
}: {
  loading?: boolean
  cameraId: string | string[]
  extraClassNames?: string
  disabled?: boolean
  hideTimer?: boolean
}) => {
  const [isLockingMounted, setLockingMounted] = useState(false)
  const { props: widgetProps } = useWidgetProps<PlayerModes.MultiMode>()

  const isCameraIdArray = Array.isArray(cameraId)
  const blockOnMatch =
    !widgetProps?.blockOnMatch ||
    (widgetProps?.camera &&
      widgetProps?.blockOnMatch &&
      isMatchWith(
        widgetProps?.camera,
        widgetProps?.blockOnMatch,
        (objValue: unknown, srcValue: unknown) =>
          Array.isArray(srcValue) ? srcValue.includes(objValue) : isEqual(objValue, srcValue)
      ))

  const isLockingAvailable = isCameraIdArray || (widgetProps?.features?.locking && blockOnMatch)

  let classNames = ''
  if (loading) classNames += 'is-loading'
  if (!cameraId || !isLockingAvailable) return null

  return (
    <div
      className={classNames}
      css={[
        wrapperStyles,
        css`
          display: ${disabled ? 'none' : 'block'};
        `
      ]}>
      {!isLockingMounted && <Loader extraStyle={loaderStyles} />}
      <LockButtonAdapter
        extraClassNames={extraClassNames}
        cameraId={cameraId}
        setLockingMounted={setLockingMounted}
        hideTimer={hideTimer}
      />
    </div>
  )
}
