import { KEY_CODES } from '../../../hooks/useKeyDown'
import { IMoveCommands } from '../models'

export const DIRECTIONS = {
  down: 'down',
  left: 'left',
  right: 'right',
  up: 'up',
  upLeft: 'upLeft',
  upRight: 'upRight',
  downRight: 'downRight',
  downLeft: 'downLeft',
  default: 'default',
  unknown: 'unknown'
} as const

export type TCameraMoveDirections = keyof typeof DIRECTIONS

export type CameraCommands = Exclude<
  keyof IMoveCommands,
  'loadCurrentData' | 'absoluteZoom' | 'pointZoom'
>

const COMMAND_NAMES: {
  [k in CameraCommands]: k
} = {
  moveDownLeft: 'moveDownLeft',
  moveRight: 'moveRight',
  moveUpLeft: 'moveUpLeft',
  moveLeft: 'moveLeft',
  moveDownRight: 'moveDownRight',
  moveDown: 'moveDown',
  moveUp: 'moveUp',
  moveUpRight: 'moveUpRight',
  zoomIn: 'zoomIn',
  zoomOut: 'zoomOut',
  stop: 'stop',
  absoluteMove: 'absoluteMove',
}

export const validateDirections = (
  direction: TCameraMoveDirections
): {
  [k in TCameraMoveDirections]: boolean
} => ({
  unknown: direction === DIRECTIONS.unknown,
  upLeft: direction === DIRECTIONS.upLeft,
  up: direction === DIRECTIONS.up,
  upRight: direction === DIRECTIONS.upRight,
  left: direction === DIRECTIONS.left,
  right: direction === DIRECTIONS.right,
  downLeft: direction === DIRECTIONS.downLeft,
  down: direction === DIRECTIONS.down,
  downRight: direction === DIRECTIONS.downRight,
  default: direction === DIRECTIONS.default
})

export const getDirectionByKeyPressed = (keyPressed: Set<string>): TCameraMoveDirections => {
  if (keyPressed.size === 1) {
    if (keyPressed.has(KEY_CODES.ArrowDown)) return DIRECTIONS.down
    if (keyPressed.has(KEY_CODES.ArrowLeft)) return DIRECTIONS.left
    if (keyPressed.has(KEY_CODES.ArrowRight)) return DIRECTIONS.right
    if (keyPressed.has(KEY_CODES.ArrowUp)) return DIRECTIONS.up
  }

  if (keyPressed.size === 2) {
    if (keyPressed.has(KEY_CODES.ArrowLeft) && keyPressed.has(KEY_CODES.ArrowUp)) {
      return DIRECTIONS.upLeft
    }
    if (keyPressed.has(KEY_CODES.ArrowRight) && keyPressed.has(KEY_CODES.ArrowUp)) {
      return DIRECTIONS.upRight
    }
    if (keyPressed.has(KEY_CODES.ArrowRight) && keyPressed.has(KEY_CODES.ArrowDown)) {
      return DIRECTIONS.downRight
    }
    if (keyPressed.has(KEY_CODES.ArrowLeft) && keyPressed.has(KEY_CODES.ArrowDown)) {
      return DIRECTIONS.downLeft
    }
  }

  return DIRECTIONS.unknown
}

export const getCommandsByKeyPressed = (keyPressed: Set<string>) => {
  if (keyPressed.has(KEY_CODES.NumpadAdd)) return COMMAND_NAMES.zoomIn
  if (keyPressed.has(KEY_CODES.NumpadSubtract)) return COMMAND_NAMES.zoomOut
  return null
}

export const getCommandByDirection = (direction: TCameraMoveDirections) => {
  switch (direction) {
    case DIRECTIONS.up: return COMMAND_NAMES.moveUp
    case DIRECTIONS.down: return COMMAND_NAMES.moveDown
    case DIRECTIONS.left: return COMMAND_NAMES.moveLeft
    case DIRECTIONS.right: return COMMAND_NAMES.moveRight
    case DIRECTIONS.upLeft: return COMMAND_NAMES.moveUpLeft
    case DIRECTIONS.upRight: return COMMAND_NAMES.moveUpRight
    case DIRECTIONS.downLeft: return COMMAND_NAMES.moveDownLeft
    case DIRECTIONS.downRight: return COMMAND_NAMES.moveDownRight
    case DIRECTIONS.default: return COMMAND_NAMES.absoluteMove
  }

  return null
}
