import { useCallback } from 'react'
import { useApiRepo } from '.'
import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'

async function fetchPoster(
  headers: HeadersInit,
  posterUrl: string,
  signal: AbortSignal
): Promise<Blob | null> {
  try {
    const res = await fetch(decodeURIComponent(posterUrl), {
      signal,
      headers
    })

    if (
      res.status === 200 &&
      ['image/jpeg', 'video/mp4', 'image/png'].some(
        (_type) => _type === res.headers.get('Content-Type')
      )
    ) {
      return await res.blob()
    }
    console.error('Preview cannot be fetched')
    return null
  } catch (e) {
    console.error(e)
    return null
  }
}

export const useGetPreview = () => {
  const api = useApiRepo() as LibApiRepository

  const fetchPreview = useCallback(
    async (snapShotUrl: string, signal: AbortSignal) => {
      const headers = await api.getHeaders()
      const previewBlob = await fetchPoster(headers, snapShotUrl, signal)

      if (signal.aborted) return
      return previewBlob
    },
    [api]
  )

  return {
    fetchPreview
  }
}
