export function debounce<F extends (...args: any[]) => any, P extends Parameters<F>>(
  func: F,
  wait: number
): (...args: P) => void {
  let timeout: any = null
  return new Proxy(func, {
    apply(target, thisArg, argArray) {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(Reflect.apply, wait, target, thisArg, argArray)
    }
  })
}
