import React, { useEffect, useRef } from 'react'

import { Widget } from '@netvision/lib-widget-renderer'

import { useWidgetProps } from '../../../../hooks'

export const LockButtonAdapter = ({
  cameraId,
  setLockingMounted,
  extraClassNames,
  hideTimer = false
}: {
  cameraId: string | string[]
  setLockingMounted: (a: boolean) => void
  extraClassNames?: string
  hideTimer?: boolean
}) => {
  const { mountChildren } = useWidgetProps()
  const ref = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (!cameraId) return

    const object = {
      type: 'widget',
      src: '@netvision/widget-react-camera-lock-button',
      // onMount single SPA hook
      onMount: () => {
        setLockingMounted(true)
      },
      props: {
        cameraIds: Array.isArray(cameraId) ? cameraId : [cameraId],
        buttonClassName: extraClassNames,
        hideTimer
      }
    } as Widget

    return mountChildren(ref.current, [object])
  }, [cameraId, cameraId.length, mountChildren, setLockingMounted, extraClassNames, hideTimer])

  return <div className="lock-button-adapter" ref={ref} />
}
