import React, { Suspense, useCallback, useEffect } from 'react'
import { useApiRepo, usePlayerStore, useWidgetProps } from '../../../../hooks'
import { observer } from 'mobx-react-lite'
import { getAnalyticsByCameraId } from '../../utils'
import { PlayerModes } from '../../../../IWidgetProps'
import { SerializedStyles } from '@emotion/react'
import { PTZModals } from './Modals'
import { useGetPTZData, usePTZStore } from '../../hooks'
import { useSensitivity } from './hooks/useSensitivity'
const PTZPanel = React.lazy(() => import('./PTZPanel/PTZPanel'))

const REQUEST_OPTIONS = { enable: false }

export const PanelAdapter = observer(({ panelStyles }: { panelStyles?: SerializedStyles }) => {
  const { props } = useWidgetProps<PlayerModes.Live>()
  const { id, features } = props!

  if (features?.ptz?.disable) return null

  const api = useApiRepo()
  const { refetch: getPTZData, data: ptzData } = useGetPTZData(id, REQUEST_OPTIONS)
  const { isFullscreen, naturalVideoHeight, naturalVideoWidth, isPlaying } = usePlayerStore()
  const {
    isPTZSupport,
    isPTZPanelOpen,
    setIsPTZSupport,
    setIsPTZConnectionError,
    togglePTZPanel,
    setActiveAnalyticsCount,
    setPTZSettings
  } = usePTZStore()

  const beforePanelMount = useCallback(async () => {
    const count = await getAnalyticsByCameraId(api, id)
    if (count > 0) {
      setActiveAnalyticsCount(count)
      return
    }
    togglePTZPanel(true)
  }, [api, id, setActiveAnalyticsCount, togglePTZPanel])

  const fullscreenImpact = features?.ptz?.mode === 'default' ? true : isFullscreen
  const hasNaturalMediaResourceSizes = Boolean(naturalVideoHeight) && Boolean(naturalVideoWidth)

  useEffect(() => {
    if (!fullscreenImpact || !hasNaturalMediaResourceSizes || !isPlaying) return
    const initialize = async () => {
      const data = await getPTZData()
      if (!data) {
        setIsPTZSupport(false)
        setIsPTZConnectionError(true)
        return
      }
      setPTZSettings(data.ptzSettings)
      setIsPTZSupport(true)
      if (features?.ptz?.initiallyShow) beforePanelMount()
    }
    initialize()
  }, [
    features?.ptz?.initiallyShow,
    fullscreenImpact,
    hasNaturalMediaResourceSizes,
    isPlaying,
    getPTZData,
    beforePanelMount,
    setIsPTZConnectionError,
    setIsPTZSupport,
    setPTZSettings
  ])

  useEffect(() => {
    if (!isFullscreen) {
      togglePTZPanel(false)
    }
  }, [isFullscreen, togglePTZPanel])

  useSensitivity({
    initialValue: ptzData?.ptzSettings?.sensitivity,
    cameraId: id
  })

  if (!ptzData || !isPTZSupport || !isPTZPanelOpen) return null

  return (
    <Suspense fallback={null}>
      <PTZPanel styles={panelStyles} />
      <PTZModals />
    </Suspense>
  )
})
