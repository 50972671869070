import { convertTimeToLocaleString } from '@src/utils/convertTimeToLocaleString'

type TCameraTime = {
  time: number
  offset: number
}

const MIN_VIDEO_WIDTH = 1000

const drawCameraTimeOnSnapshot = (
  ctx: CanvasRenderingContext2D,
  canvasWidth: number,
  cameraTime: TCameraTime
) => {
  ctx.font = '48px Arial'
  ctx.textAlign = 'right'
  ctx.textBaseline = 'top'
  ctx.fillStyle = 'white'
  ctx.strokeStyle = 'black'
  ctx.lineWidth = 2
  const text = convertTimeToLocaleString(cameraTime?.time - cameraTime?.offset, 'ru-RU')
  const x = canvasWidth - (canvasWidth / 100 * 4.5) // Отступ от правого верхнего края = 4,5%
  const y = 50
  ctx.fillText(text, x, y)
  ctx.strokeText(text, x, y)
}

export const getSnapshot = (
  videoNode: HTMLVideoElement,
  width = 1920,
  height = 1080,
  cameraTime?: TCameraTime
) => {
  const canvas = document.createElement('canvas')
  const ratio = width / height
  canvas.width = width < MIN_VIDEO_WIDTH ? MIN_VIDEO_WIDTH : width
  canvas.height = width < MIN_VIDEO_WIDTH ? (MIN_VIDEO_WIDTH / ratio) : height
  const ctx = canvas.getContext('2d')
  if (!ctx) return
  ctx?.drawImage(videoNode, 0, 0, canvas.width, canvas.height)

  if (cameraTime) {
    drawCameraTimeOnSnapshot(ctx, canvas.width, cameraTime)
  }

  const image = canvas.toDataURL('image/png')
  const link = document.createElement('a')
  link.download = 'screenshot.png'
  link.href = image
  link.click()
}
