import React from 'react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import { useLocaleContext } from '@src/Root'

type PTZConnectProps = {
  onHide: () => void
}

export const PTZConnectModal = ({ onHide }: PTZConnectProps) => {
  const { localize } = useLocaleContext()

  return (
    <Dialog
      closable={false}
      onHide={onHide}
      visible
      header={localize('ptzControls.connectionError.header')}
      footer={
        <Button
          onClick={onHide}
          className="p-button-secondary p-button-outlined"
          label={localize('ptzControls.connectionError.close')}
        />
      }>
      <div className="p-px-3">{localize('ptzControls.connectionError.message')}</div>
    </Dialog>
  )
}
