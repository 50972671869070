/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment, useMemo, useState } from 'react'
import { PlayerModes } from '../../../../IWidgetProps'
import { useStreamSwitcher } from '../../hooks'
import { Select } from './../../../../components/Select/Select'

export const Switcher = ({
  mode,
  loading,
  secondary
}: {
  mode: PlayerModes
  loading?: boolean
  secondary?: boolean
}) => {
  const { isLoading, getAllStreams, getActiveStream } = useStreamSwitcher()
  const streams = getAllStreams()
  const activeStream = getActiveStream(mode)

  const streamsList = useMemo(
    () => [...streams.values()].map((stream) => ({ id: stream.id, title: stream.title })),
    [streams]
  )

  const componentLoading = isLoading || !activeStream || loading

  const [activeId, setActiveId] = useState(activeStream?.id)

  return (
    <Fragment>
      <Select
        secondary={secondary}
        icon="mdi mdi-32px mdi-cog"
        activeId={activeId}
        disabled={componentLoading}
        onSelect={(item) => setActiveId(item.id as string)}
        options={streamsList}
      />
    </Fragment>
  )
}
