import { dictionary } from '../locales'
import { get } from 'lodash-es'
import React, { createContext, useContext, useState } from 'react'

interface ILocaleContext {
  locale: any
  setLocale: (locale: any) => void
  localize: <T>(path: string, ...rest: Array<string | number>) => T | string
}

// TODO временное решение locales
export const LocaleContext = createContext<ILocaleContext>(dictionary as any)

export const useLocale = () => useContext(LocaleContext)

export const LocaleProvider = ({ children }: any) => {
  const [value] = useState<any>(dictionary)
  const [locale, setLocale] = useState<any>('ru')
  const localize = <T, >(path: string, ...rest: Array<string | number>): T | string => {
    const data = get(value, `${locale}.${path}`)
    if (typeof data !== 'string') return data as T
    const message: string = String(data)
    return !rest.length
      ? message
      : message
          .split(' ')
          .reduce((acc, next) => {
            acc.push(
              next.includes('{param}') ? next.replace('{param}', String(rest.shift())) : next
            )
            return acc
          }, [] as Array<string | number>)
          .join(' ')
  }

  return (
    <LocaleContext.Provider value={{ localize, locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}
