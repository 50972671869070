import React, { ReactNode, ReactPortal, createContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { Tooltip } from 'primereact/tooltip'

interface ITooltipPayload {
  elementId: string
  message: string
  config: Record<string, string>
}

interface ITooltipContext {
  showTooltip(options: ITooltipPayload): void
}

export const TooltipContext = createContext<ITooltipContext>({
  showTooltip: () => {},
})

export const TooltipProvider = ({ children }: { children: ReactNode }) => {
  const [tooltipPortal, setTooltipPortal] = useState<ReactPortal | null>(null)

  const showTooltip = ({ elementId, message, config }: ITooltipPayload) => {
    setTooltipPortal(createPortal(
      <Tooltip
        target={`#${elementId}`}
        content={message}
        appendTo={document.fullscreenElement || document.body}
        { ...config }
      />,
      document.fullscreenElement || document.body
    ))
  }

  return (
    <TooltipContext.Provider value={{ showTooltip }}>
      {children}
      {tooltipPortal}
    </TooltipContext.Provider>
  )
}
