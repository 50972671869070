/** @jsx jsx */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { css, jsx } from '@emotion/react'

import { useGetPreview, usePlayerStore, useWidgetProps } from '../../hooks'

import { ZoomZone } from '@netvision/front-utils/lib/react/components/ZoomZone'
import { observer } from 'mobx-react-lite'
import { BasePreview } from './screens/BasePreview'
import { useLocaleContext } from '@src/Root'

const previewElementStyle = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;

  video {
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

const digitalZoomWrapper = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

interface Props {
  id: string
  url?: string
  children?: React.ReactNode
}

const DEFAULT_UPDATE_INTERVAL = 6000
const PlayerPreview = (props: Props) => {
  const { url: snapshotUrl, id } = props
  const { props: widgetProps } = useWidgetProps()
  const { previewOptions, stalledOptions, disableBlur } = widgetProps!

  const [isNoPreview, setIsNoPreview] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)

  const previewNode = useRef<HTMLVideoElement>(null!)
  const autoUpdateIntervalId = useRef(0)

  const { isPlayerStalled } = usePlayerStore()
  const { fetchPreview } = useGetPreview()
  const { localize } = useLocaleContext()

  const initializePreview = useCallback(
    async (snapshotUrl: string, signal: AbortSignal) => {
      try {
        const previewBlob = await fetchPreview(snapshotUrl, signal)
        if (signal.aborted) return

        if (!previewBlob) {
          setIsNoPreview(true)
          setPreviewUrl(null)
          return
        }

        const url = window.URL.createObjectURL(previewBlob)
        const isVideoPoster = previewBlob.type.startsWith('video')
        previewNode.current[isVideoPoster ? 'src' : 'poster'] = url
        setIsNoPreview(false)
        setPreviewUrl(url)
      } catch (e) {
        console.error(e)
        setIsNoPreview(true)
        setPreviewUrl(null)
      }
    },
    [fetchPreview]
  )

  useEffect(() => {
    if (!snapshotUrl) {
      setPreviewUrl(null)
      return
    }
    const abortController = new AbortController()

    initializePreview(snapshotUrl, abortController.signal)

    if (isPlayerStalled) {
      autoUpdateIntervalId.current = window.setInterval(
        () => initializePreview(snapshotUrl, abortController.signal),
        stalledOptions?.previewUpdateInterval ||
          previewOptions?.previewUpdateInterval ||
          DEFAULT_UPDATE_INTERVAL
      )
    }

    const videoElem = previewNode.current
    return () => {
      abortController.abort()
      autoUpdateIntervalId.current && clearInterval(autoUpdateIntervalId.current)
      URL.revokeObjectURL(videoElem.src || videoElem.poster)
    }
  }, [initializePreview, isPlayerStalled, snapshotUrl])

  useEffect(() => {
    const videoElem = previewNode.current
    if (!videoElem || !previewUrl || disableBlur) return

    const videoElemCopy = videoElem.cloneNode(true) as HTMLVideoElement
    videoElemCopy.id = `react-player-preview-${id}-blurred`
    videoElemCopy.width = window.innerWidth
    videoElemCopy.height = window.innerHeight
    videoElemCopy.style.position = 'absolute'
    videoElemCopy.style.left = '0'
    videoElemCopy.style.top = '0'
    videoElemCopy.style.objectFit = 'cover'
    videoElemCopy.style.filter = 'blur(30px)'

    videoElem.before(videoElemCopy)

    return () => {
      videoElemCopy.remove()
    }
  }, [previewUrl, id, disableBlur])
  return (
    <div css={previewElementStyle}>
      {props.children}
      {isNoPreview && (
        <BasePreview label={localize('ptzControls.stream.streamNoPreview')} fill="#3c72ff" />
      )}
      {/* {previewUrl && (
        <div css={digitalZoomWrapper}>
          <ZoomZone elements={[previewNode.current]} />
        </div>
      )} */}
      <video ref={previewNode} id={`react-player-preview-${id}`} />
    </div>
  )
}

const PlayerPreviewMemo = React.memo(observer(PlayerPreview))

export { PlayerPreviewMemo as PlayerPreview }
