/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react'
import React, { MutableRefObject, useImperativeHandle, useRef, useState } from 'react'
import { PlayPauseButton, FullscreenToggle } from './controls'
import { PlayerApi } from '../../packages/Player'
import { useWidgetProps } from '../../hooks'
import { PlayerModes } from '../../IWidgetProps'

interface Props {
  id: string
  playerApi?: MutableRefObject<PlayerApi>

  loading?: boolean
  visible: boolean
  disableArchiveButtons?: boolean
  isPlaying?: boolean
  isFullscreen?: boolean

  styles?: SerializedStyles

  centerSide?: React.ReactNode
  leftSide?: React.ReactNode
  outSide?: React.ReactNode
  children?: React.ReactNode
  bigMode?: boolean
  onPlay: (flag: boolean) => void
  customFullScreenToggle?: () => void
}

const panelWrapper = css`
  // стили контрол панель по-умлочанию
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
  /* position: relative; */
  transition: opacity var(--transition-duration) ease;
  opacity: 1;

  &.hide {
    opacity: 0;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    padding-top: calc(45rem / var(--bfs));
    height: calc(200rem / var(--bfs));
    background-image: var(--player-controls-gradient);
  }

  .controls-wrapper {
    width: 100%;
    padding: calc(15rem / var(--bfs));
  }
`

const panelSection = css`
  gap: calc(20rem / var(--bfs));
  &.is-fullscreen {
    gap: calc(10rem / var(--bfs));
  }
`

export const ControlPanel = React.memo(
  React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      playerApi,
      loading,
      visible,
      onPlay,
      isPlaying = true,
      isFullscreen = true,
      styles,
      disableArchiveButtons,
      customFullScreenToggle,
      bigMode
    } = props
    // const { isFullscreen, isPlaying } = usePlayerStore()
    const { props: wp } = useWidgetProps()
    const [isVisiblePanel, setIsVisiblePanel] = useState(false)
    const handleFullscreen = () => {
      if (customFullScreenToggle) {
        customFullScreenToggle()
      } else {
        // !isFullscreen && !isPlaying && onPlay(false)
        playerApi && playerApi.current.getPlayerInstance()?.toggleFullScreen()
      }
    }

    let panelSectionExtraClass = ''
    let extraClassNames = visible || isVisiblePanel ? 'show' : 'hide'
    if (isFullscreen) {
      extraClassNames += ' is-fullscreen'
      panelSectionExtraClass += 'is-fullscreen'
    }

    const isArchiveMode = PlayerModes.Archive === wp!.playerMode && !disableArchiveButtons
    const isSavedArchiveModePlayer = PlayerModes.Saved === wp!.playerMode && isFullscreen
    const isArchiveOrSavedModePlayer = isArchiveMode || isSavedArchiveModePlayer || bigMode

    const controlPanelRef = useRef<HTMLDivElement>(null!)

    useImperativeHandle(ref, () => controlPanelRef.current, [])

    return (
      <div
        onMouseEnter={() => setIsVisiblePanel(true)}
        onMouseLeave={() => setIsVisiblePanel(false)}
        ref={controlPanelRef}
        css={[panelWrapper, styles]}
        className={`control-panel p-d-flex p-ai-center p-jc-between ${extraClassNames}`}>
        {props.outSide}
        <div className="p-jc-between p-d-flex p-ai-center controls-wrapper">
          {props?.leftSide && (
            <div
              css={panelSection}
              className={`${panelSectionExtraClass} p-d-flex p-ai-center side-left`}>
              {props?.leftSide}
            </div>
          )}
          <div
            css={panelSection}
            className={`${panelSectionExtraClass} p-d-flex p-ai-center side-center`}>
            <PlayPauseButton
              isPlaying={isPlaying}
              secondary={isArchiveOrSavedModePlayer}
              disabled={loading}
              id={props.id}
              onClick={onPlay}
            />
            {props?.centerSide}
          </div>
          <div
            css={panelSection}
            className={`${panelSectionExtraClass} p-d-flex p-ai-center side-right`}>
            {props?.children}
            {/* // TODO переделать кнопку громкости */}
            {/* <VolumeControl secondary={isArchiveOrSavedModePlayer} disabled={loading} id={props.id} /> */}
            <FullscreenToggle
              isFullscreen={isFullscreen}
              secondary={isArchiveOrSavedModePlayer}
              id={props.id}
              onClick={handleFullscreen}
            />
          </div>
        </div>
      </div>
    )
  })
)
