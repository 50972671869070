import { IPtzValues } from '../models'

interface PresetPositionMatching<A> {
  readonly equals: (a: A, b: A) => boolean;
}

const getEqNumber = (threshold: number): PresetPositionMatching<number> => ({
	equals: (a, b) => Math.abs(a - b) < threshold,
})

export const getPositionEqualFunction = (threshold: IPtzValues): PresetPositionMatching<IPtzValues> => {
  const eqEntries = Object.entries(threshold).map(
    ([key, value]) => [key, getEqNumber(value)] as [keyof IPtzValues, PresetPositionMatching<number>]
  )
  return {
    equals: (a, b) => {
      if (a === b) return true
      return eqEntries.every(([key, eq]) => eq.equals(a[key], b[key]))
    }
  }
}
