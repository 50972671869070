/** @jsx jsx */
import { jsx } from '@emotion/react'

import React, { useCallback, useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import { useApiRepo } from '../../../hooks'
import { TProcessKeys, dialogMessage, errorColor, processStateKeys } from './common'
import { PresetHooks } from '../../Presets'
import { useLocaleContext } from '@src/Root'

interface Props {
  presetId: string
  onHide: () => void
}

const EXTRA_PROCESS_STATE_KEY = 'checkAnalytics' as const

export const DeletePresetModal = (props: Props) => {
  const { onHide, presetId } = props
  const { localize } = useLocaleContext()

  const api = useApiRepo()
  const { refetch } = PresetHooks.usePresetData()
  const { removeCameraPreset } = PresetHooks.usePresetCommand()
  const [availableAnalyticsCount, setAvailableAnalyticsCount] = useState(-1)
  const [processState, setProcessState] = useState<(TProcessKeys | typeof EXTRA_PROCESS_STATE_KEY) | null>(null)

  const countAnalyticsByPreset = useCallback(async (presetId: string) => {
    try {
      const { results } = await api.getEntitiesList({
        limiter: {
          type: 'AssignmentGroup'
        },
        filter: {
          q: `parameters.presetId=='${presetId}'`
        }
      })
      return results.length
    } catch (e) {
      console.error(e)
      return -1
    }
  }, [api])

  useEffect(() => {
    let unmount = false
    const checkAvailableAnalyticsCount = async () => {
      try {
        if (unmount) return
        setProcessState(EXTRA_PROCESS_STATE_KEY)
        const availableCount = await countAnalyticsByPreset(presetId)
        if (unmount) return
        setAvailableAnalyticsCount(availableCount)
        setProcessState(null)
      } catch (e) {
        setProcessState(processStateKeys.error)
        console.error(e)
      }
    }

    checkAvailableAnalyticsCount()

    return () => {
      unmount = true
    }
  }, [countAnalyticsByPreset, presetId])

  const handleDeletePreset = async () => {
    try {
      setProcessState(processStateKeys.loading)
      await removeCameraPreset(presetId)
      await refetch()
      onHide()
    } catch (e) {
      setProcessState(processStateKeys.error)
      console.error(e)
    } finally {
      setProcessState(null)
    }
  }

  const isLoading = processState === processStateKeys.loading
  const isError = processState === processStateKeys.error
  const isCheckAnalytics = processState === EXTRA_PROCESS_STATE_KEY
  const hasActiveAnalytics = availableAnalyticsCount > 0

  return (
    <Dialog
      visible
      onHide={onHide}
      closable={false}
      header={localize('ptzControls.presets.delete')}
      footer={
        isLoading
          ? null
          : (
          <React.Fragment>
            {!hasActiveAnalytics && (
              <Button
                icon={isLoading ? 'mdi mdi-20x mdi-loading mdi-spin' : undefined}
                disabled={isLoading}
                onClick={handleDeletePreset}
                className={'p-button-danger'}
                label={localize('ptzControls.presets.yes')}
              />
            )}
            <Button
              disabled={isLoading}
              onClick={onHide}
              className="p-button-secondary p-button-outlined"
              label={hasActiveAnalytics ? localize('ptzControls.presets.ok') : localize('ptzControls.presets.no')}
            />
          </React.Fragment>
        )
      }>
      <div className="p-px-3 p-d-flex p-flex-column" css={dialogMessage}>
        <span>
          {isCheckAnalytics
            ? localize('ptzControls.presets.loadingDeleteDetail')
            : hasActiveAnalytics
            ? localize('ptzControls.presets.cannotDeleteDetail', availableAnalyticsCount.toString())
            : localize('ptzControls.presets.deleteDetail')}
        </span>
        {isError && <span style={errorColor}>{localize('ptzControls.presets.unexpectedError')}</span>}
      </div>
    </Dialog>
  )
}
